export class Storage {
    constructor() {
        this._localStoragePrefix = "vmb_";
    }

    canUseLocalStorage() {
        // Determine is localstarege usable.
        try {
            localStorage.setItem(this._localStoragePrefix + "_writeTest", "testValue");
        } catch(e) {
            console.log(e);
            return false;
        }

        return true;

    }

    clearAll() {
        const length = localStorage.length;
        const remain_keys = [
            this._localStoragePrefix + "skip-maestro-card",
            this._localStoragePrefix + "maestro-question-2",
            this._localStoragePrefix + "maestro-question-3",
            this._localStoragePrefix + "maestro-question-4",
            this._localStoragePrefix + "maestro-question-faceType",
            this._localStoragePrefix + "maestro-question-gender"
        ];
        let delete_keys = [];

        for(let ii=0;ii<length;ii++) {
            const key = localStorage.key(ii);
            if($.inArray(key, remain_keys) === -1 && key.indexOf(this._localStoragePrefix) === 0) {
                delete_keys.push(key);
            }
        }

        for(let ii=0;ii<delete_keys.length;ii++) {
            localStorage.removeItem(delete_keys[ii]);
        }
    }

    setItem(key, value) {

        localStorage.setItem(this._localStoragePrefix + key, value);

    }

    getItem(key) {

        if(!this.canUseLocalStorage()) {
            return null;
        }

        return localStorage.getItem(this._localStoragePrefix + key);
    }

    clearItem(key) {
        if(this.getItem(key) == null) {
            return;
        }
        localStorage.removeItem(this._localStoragePrefix + key);
    }


    /**************************************************************************
     * サーバAPIレスポンスからマエストロカード情報を更新する.
     * @param data
     **************************************************************************/
    updateMaestroCardWithServerResponse(data) {
        if(data.maestroCard != null) {

            /*
            maestroCard_answer01: 1
            maestroCard_answer02: 2
            maestroCard_answer03: (2) [4, 5]
            maestroCard_answer04: (3) [1, 7, 15]
            maestroCard_answer05: (4) [1, 5, 6, 11]
             */

            // 性別.
            this.saveMaestroCardQuestion("gender", [data.maestroCard.maestroCard_answer01.toString()]);
            // 顔タイプ.
            this.saveMaestroCardQuestion("faceType", [data.maestroCard.maestroCard_answer02.toString()]);
            // 設問２〜４.
            this.saveMaestroCardQuestion("2", data.maestroCard.maestroCard_answer03.map(function(val) { return val.toString(); }));
            this.saveMaestroCardQuestion("3", data.maestroCard.maestroCard_answer04.map(function(val) { return val.toString(); }));
            this.saveMaestroCardQuestion("4", data.maestroCard.maestroCard_answer05.map(function(val) { return val.toString(); }));
        }
    }


    /***************************************************************************
     * マエストロカードの選択状態を保存する.
     * @param questionIndex
     * @param selectedElements
     ***************************************************************************/
    saveMaestroCardQuestion(questionIndex, selectedValues) {
        this.setItem("maestro-question-" + questionIndex, JSON.stringify(selectedValues));
    }


    /***************************************************************************
     * マエストロカードの選択状態を復元する
     * @param questionIndex
     * @returns {*}
     ***************************************************************************/
    loadMaestroCardQuestion(questionIndex) {
        let data = this.getItem("maestro-question-" + questionIndex);
        if(data == null) {
            return [];
        }
        return JSON.parse(data);
    }


    /***************************************************************************
     * マエストロカード登録結果から、ユーザIDをlocalStorageへ保存.
     * @param data
     ***************************************************************************/
    saveUserId(data) {
        this.setItem("user_id", data.USER_ID);
    }


    /****************************************************************************
     * localStorageからユーザIDを復元
     * @returns {*}
     ***************************************************************************/
    getUserId() {
        let userId = this.getItem("user_id");
        if(userId == null || userId === "null") {
            return "";
        }
        return userId;
    }


    /******************************************************************************
     * マエストロカード作成スキップを保存.
     *****************************************************************************/
    saveMaestroSkipped() {
        this.setItem("skip-maestro-card", true);
    }


    /****************************************************************************
     * マエストロカード作成スキップを取得.
     * @returns {boolean}
     *****************************************************************************/
    getMaestroSkipped() {
        if(this.getItem("skip-maestro-card") == null) {
            return false;
        }
        return true;
    }

    /****************************************************************************
     * マエストロカード作成スキップをクリア.
     *****************************************************************************/
    clearMaestroSkipped() {
        if(this.getItem("skip-maestro-card") == null) {
            return;
        }
        this.clearItem("skip-maestro-card");
    }

    /****************************************************************************
     * マエストロメニューの自動表示を保存.
     *****************************************************************************/
    saveDisplayMaestroMenu() {
        this.setItem("show-maestro-menu", true);
    }

    /****************************************************************************
     * マエストロカードの自動表示を取得.
     * @returns {boolean}
     *****************************************************************************/
    getDisplayMaestroMenu() {
        if(this.getItem("show-maestro-menu") == null) {
            return false;
        }
        return true;
    }

    saveOutOfStockNotice() {
        this.setItem("out-of-stock-notice", true);
    }

    getOutOfStockNotice() {
        if(this.getItem("out-of-stock-notice") == null) {
            return false;
        }
        return true;
    }

    clearOutOfStockNotice() {
        this.clearItem("out-of-stock-notice");
    }
}
