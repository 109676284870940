import {ApiCaller} from "./api-caller";
import {MenuManager} from "./menu-manager";

export class Reserve {

    constructor(controller) {
        this._controller = controller;
        this._prefData = [];
        this._storeListBaseElement = null;
        this._searchConditionText = "";
        this._restoreShopNo = "";
        this.initialize();
    }

    initialize() {
        this.initializeElements();
        this.setupEvents();
    }


    restoreStatus() {

        // hidden値の内容から選択状態を復元する.
        const prefId = $("input[name=prefId]").val();
        const cityId = $("input[name=cityId]").val();
        const shopNo = $("input[name=shopNo]").val();

        if (prefId) {
            this.updateCitySelect(prefId);
            $("#reserve_store_address_pref").val(prefId);
        }

        if (cityId) {
            $("#reserve_store_address_city").val(cityId);
        }

        this._restoreShopNo = shopNo;
        this.saveSearchConditionText();
        this.doReserveStoreSearch(prefId, cityId);
    }


    initializeElements() {

        // $("#reserve_store_address_pref").empty();

        // ベースエレメントをコピーし保持.
        if(this._storeListBaseElement == null) {
            this._storeListBaseElement = $(".c-storeResult .c-storeList label").eq(0).clone(true);
        }

        $(".c-storeResult .c-storeResult__title").text("");
        $(".c-storeResult .c-storeResult__nom").text("");
        $(".c-storeResult .c-storeList").empty();

    }

    setupEvents() {

        const _this = this;
        /***********************************************************************************************
         * 来店予約内イベント.
         ***********************************************************************************************/
        $("#reserve_store_address_pref").on("change", function() {
            _this.updateCitySelect($(this).val());
        });

        $("#reserve-store-search-button").on("click", function(e) {

            e.preventDefault();

            // 検索条件を保存.
            _this.saveSearchConditionText();

            let param = {};
            param["pref_id"]        = $("#reserve_store_address_pref").val();
            param["city_id"]        = $("#reserve_store_address_city").val();
            _this.doReserveStoreSearch(param["pref_id"], param["city_id"]);
            return false;
        })
    }

    pickupPrefDataByPrefectureItemId(pref_id) {
        for(let ii=0;ii<this._prefData.length;ii++) {
            if(this._prefData[ii]["prefectureItem_id"] === pref_id) {
                return this._prefData[ii];
            }
        }

        return null;
    }

    saveSearchConditionText() {
        let conditions = [];

        // 都道府県。
        if($("#reserve_store_address_pref").val() !== "") {
            conditions.push($("#reserve_store_address_pref option:selected").text());
        }

        // 町名.
        if($("#reserve_store_address_city").val() !== "") {
            conditions.push($("#reserve_store_address_city option:selected").text());
        }

        if(conditions.length === 0) {
            this._searchConditionText = "検索条件なし";
        }else{
            this._searchConditionText = conditions.join("、");
        }
    }

    /*********************************************************************************
     * 店舗検索の都道府県初期化.
     *********************************************************************************/
    initializeStorePrefecture(data) {

        this._prefData = data["pref"];

        // 初期化.
        $("#reserve_store_address_pref").empty();
        $("#reserve_store_address_pref").append('<option value="">都道府県：全て</option>')

        if(typeof $("#reserve_store_address_pref").data("initialized") === "undefined") {
            for(let ii=0;ii<this._prefData.length;ii++) {

                // active_countが0の場合はスキップする.
                if(typeof this._prefData[ii]["active_count"] !== "undefined") {
                    if(this._prefData[ii]["active_count"] == 0) {
                        continue;
                    }
                }

                let option = $("<option>");
                option.attr("value", this._prefData[ii]["prefectureItem_id"]);
                option.text(this._prefData[ii]["prefectureItem_name"]);
                $("#reserve_store_address_pref").append(option);
            }

            $("#reserve_store_address_pref").data("initialized", true);
        }

        // hidden値から復元.
        this.restoreStatus();
    }


    /*********************************************************************************
     * 店舗検索の市区町村初期化.
     *********************************************************************************/
    updateCitySelect(pref_id) {
        const prefectureData = this.pickupPrefDataByPrefectureItemId(pref_id);

        // 初期化.
        $("#reserve_store_address_city").empty();
        $("#reserve_store_address_city").append('<option value="">市区町村：全て</option>')

        // 都道府県データがなければ戻る.
        if(prefectureData == null){
            return false;
        }

        for(let ii=0;ii<prefectureData["city"].length;ii++) {

            // active_countが0の場合はスキップする.
            if(typeof prefectureData["city"][ii]["active_count"] !== "undefined") {
                if(prefectureData["city"][ii]["active_count"] == 0) {
                    continue;
                }
            }

            let option = $("<option>");
            option.attr("value", prefectureData["city"][ii]["city_id"]);
            option.text(prefectureData["city"][ii]["city_name"]);
            $("#reserve_store_address_city").append(option);
        }
    }



    /*********************************************************************************
     * 店舗リストの表示.
     *********************************************************************************/
    updateStoreList(shopList) {

        // 検索条件表示.
        $(".c-storeResult .c-storeResult__title").text(this._searchConditionText);
        $(".c-storeResult .c-storeResult__nom").text(shopList.length + "件");

        // リストを空にする.
        $(".c-storeResult .c-storeList").empty();

        // 要素生成.
        const len = shopList.length;
        for(let ii=0;ii<len;ii++) {
            let tmpBase = this._storeListBaseElement.clone(true);

            // inputタグ.
            let input = $("<input type='radio'>");
            input.attr("name", "shop_no");
            input.attr("id", "store_no_" + shopList[ii].shop_no);
            input.val(shopList[ii].shop_no);
            $(".c-storeResult .c-storeList").append(input);

            // hidden値とのチェック。
            if(shopList[ii].shop_no == this._restoreShopNo) {
                input.prop("checked", true);
            }

            // 情報設定.
            tmpBase.attr("for", "store_no_" + shopList[ii].shop_no);
            // tmpBase.find(".storeInfo__title a").text(shopList[ii].shop_name);
            // tmpBase.find(".storeInfo__title a").attr("href", "/shop/" + shopList[ii].shop_no + "/");
            // tmpBase.find(".storeInfo__title a").attr("target", "_blank");

            // 店舗名はリンクさせない.
            tmpBase.find(".storeInfo__title").text(shopList[ii].shop_name);

            tmpBase.find(".storeInfo__text.address").html("〒" + shopList[ii].shop_zip + "<br>" + shopList[ii].prefectureItem_name + shopList[ii].city_name + shopList[ii].shop_address);
            tmpBase.find(".storeInfo__text.tel").text(shopList[ii].shop_tel);
            tmpBase.find(".storeInfo__text.open-hour").text(shopList[ii].shop_beginTime + "〜" + shopList[ii].shop_endTime);
            tmpBase.find(".storeInfo__text.holiday").text(shopList[ii].shop_holiday);
            if(shopList[ii].shop_facility !== "") {
                tmpBase.find(".storeInfo__text.facility").text(shopList[ii].shop_facility);
            }else{
                tmpBase.find(".storeInfo__text.facility").parent().remove();
            }

            // tmpBase.find(".storeInfo__storeNo").text("店舗 No：" + shopList[ii].shop_no);
            // 店舗Noの代わりに詳細へのリンクにする.
            let shopLink = $("<a>");
            shopLink.attr("href", "/shop/" + shopList[ii].shop_no + "/");
            shopLink.attr("target", "_blank");
            shopLink.html("<span class='storeInfo__storeNo'>店舗の詳細を見る</span>");
            tmpBase.find(".storeInfo__information .storeInfo__storeNo").remove();
            tmpBase.find(".storeInfo__information").prepend(shopLink);

            // 駐車場.
            if(shopList[ii].shop_parkingFlag === "2") {
                tmpBase.find(".storeInfo__store li.parking").remove();
            }
            // 提携駐車場でない場合
            else if(shopList[ii].shop_parkingFlag === "1") {
                //<img src="/img/common/icon_parking.svg" alt="提携駐車場" class="storeInfo__storeIcon"><span class="storeInfo__storeText">提携駐車場<br>あり</span>
                tmpBase.find(".storeInfo__store li.parking img").attr("alt", "駐車場");
                tmpBase.find(".storeInfo__store li.parking img").attr("src", "/img/common/icon_parking_blue.svg");
                tmpBase.find(".storeInfo__store li.parking .storeInfo__storeText").html("駐車場<br>あり");
                tmpBase.find(".storeInfo__store li.parking .storeInfo__storeText").addClass("blue");
            }


            // 予約の店舗一覧では、電話番号・営業時間・定休日を非表示にする.
            tmpBase.find(".storeInfo__text.tel").closest("dd").remove();
            tmpBase.find(".storeInfo__text.open-hour").closest("dd").remove();
            tmpBase.find(".storeInfo__text.holiday").closest("dd").remove();



            // コンタクト.
            // if(shopList[ii].shop_clFlag !== "1") {
            //     tmpBase.find(".storeInfo__store li.contact-lens").remove();
            // }
            // 来店予約ではコンタクトフラグは非表示.
            tmpBase.find(".storeInfo__store li.contact-lens").remove();


            $(".c-storeResult .c-storeList").append(tmpBase);
        }

        // 1つ目をcheckedにする.
        if(this._restoreShopNo === "") {
            $(".c-storeResult .c-storeList input[type=radio]").eq(0).prop("checked", true);
        }
    }



    doReserveStoreSearch(pref_id, city_id) {
        let param = {};
        param.prefectureItem_id = pref_id;
        param.city_id = city_id;

        // 来店予約.
        if(this._controller.isReservedPage()) {
          param.displayType = 1;
        }

        // お問い合わせ.
        if(this._controller.isContactPage()) {
          param.displayType = 2;
        }


      this._controller._apiCaller.get(ApiCaller.EndPoints.ReserveStoreSearch,param);
    }
}


