import {StoreMenu} from './store-menu';
import {ProductMenu} from './product-menu';
import {CardMenu} from './card-menu';
import {ApiCaller} from "./api-caller";

export class MenuManager {
    constructor(controller) {
        this._controller = controller;
        this._currentActiveMenu = MenuManager.ClassName.None;
        this._storeMenu = new StoreMenu(this);
        this._productMenu = new ProductMenu(this);
        this._cardMenu = new CardMenu(this);
    }

    initialize() {
        this.setupEvents();
        this.setupGlobalNavigation();
        this.checkInitialState();
    }

    setupEvents() {
        const _this = this;

        // ヘッダーのXボタン.
        $(".l-header__close").on("click", function() {
            window.close();
        });
    }

    setupGlobalNavigation() {

        var _this = this;

        // ハンバーガーメニュー.
        $(".l-headerNav__li.gnav button").on("click", function() {
            _this.toggleMenuAt(MenuManager.ClassName.Hamburger);
            return false;
        });

        if (window.matchMedia( "(min-width: 980px)" ).matches) {

          // マウスオーバーで開くを追加.
          $(".l-headerNav__li.gnav button").on("mouseenter", function() {
            _this.toggleMenuAt(MenuManager.ClassName.Hamburger);
            return false;
          });

          // メニューからマウスが外れたら閉じるを追加.
          $(".l-gnav").on("mouseleave", function() {
            _this.toggleMenuAt(_this._currentActiveMenu);
            return false;
          });
        }

        // 店舗検索。
        $(".l-headerNav__li.store .l-headerNav__nav").on("click", function() {
            _this.toggleMenuAt(MenuManager.ClassName.StoreSearch);
            return false;
        });


        // マエストロカード.
        $(".l-headerNav__li.maestro .l-headerNav__nav").on("click", function() {
            _this._cardMenu.openCardMenuCall();
            _this.toggleMenuAt(MenuManager.ClassName.Maestro);
            const isOutOfStock = _this._controller._storage.getOutOfStockNotice();
            if(isOutOfStock) {
                _this._cardMenu.showModal('試したい商品の<br>在庫がなくなりました<br>別の商品を選択してください');
                _this._controller._storage.clearOutOfStockNotice();
            }

            // 選択値を保存されたものに戻す.
            _this._controller._cardManager.restoreMenuSelectedValues();
            _this._controller._cardManager.menuValidate(null);
            return false;
        });

        // 商品検索.
        $(".l-headerNav__li.search .l-headerNav__nav").on("click", function() {
            _this.toggleMenuAt(MenuManager.ClassName.ProductSearch);
            return false;
        });

        // SP閉じるボタン.
        $(".l-menu__closeBtn").on("click", function() {
            _this.toggleMenuAt(_this._currentActiveMenu);
            return false;
        })

        // モーダル背景をクリックで閉じる.
        $(".l-containerWrap").on("click", function() {
            _this.toggleMenuAt(_this._currentActiveMenu);
            return false;
        });
    }

    checkInitialState() {
        for(var key in MenuManager.ClassName) {
            if(MenuManager.ClassName[key] !== "") {
                if($(".l-headerNav__li." + MenuManager.ClassName[key]).hasClass("-open")) {
                    this._currentActiveMenu = MenuManager.ClassName[key];
                }
            }
        }
    }


    toggleMenuAt(menuName) {

        // 今開いているのと同じメニューの場合は閉じて戻る.
        if(this._currentActiveMenu === menuName) {
            $(".l-container").removeClass("-show");
            $(".l-headerNav__li." + this._currentActiveMenu).removeClass("-open");
            this._currentActiveMenu = "";
            return false;
        }

        // 何もメニューを開いていない.
        if(this._currentActiveMenu === "") {
            $(".l-container").toggleClass("-show");
        }
        // 何か開いている.
        else{
            // 既に開いているメニューを閉じる.
            $(".l-headerNav__li." + this._currentActiveMenu).removeClass("-open");
        }

        // 開いているメニューを保存.
        this._currentActiveMenu = menuName;

        switch(menuName) {

            /***********************************************
             * ハンバーガーメニュー.
             ***********************************************/
            case MenuManager.ClassName.Hamburger:
                break;


            /***********************************************
             * 店舗検索.
             ***********************************************/
            case MenuManager.ClassName.StoreSearch:
                break;

            /***********************************************
             * マエストロカード.
             ***********************************************/
            case MenuManager.ClassName.Maestro:
                $(".l-headerNav__li.maestro .l-menu").scrollTop(0);
                break;


            /***********************************************
             * 商品検索.
             ***********************************************/
            case MenuManager.ClassName.ProductSearch:
                $(".l-headerNav__li.search .l-menu").scrollTop(0);
                break;
        }

        $(".l-headerNav__li." + this._currentActiveMenu).addClass("-open");
    }


    /************************************************************************************************************************
     * 店舗検索実行.
     * @param param
     *************************************************************************************************************************/
    doStoreSearch(pref_id, city_id, contact_flag) {
        let param = {};
        param.prefectureItem_id = pref_id;
        param.city_id = city_id;
        param.containClosedFlag = 1;    // 閉店店舗も含める
        if(contact_flag) {
            param.shop_clFlag = 1;
        }

        // 来店予約.
        if(this._controller.isReservedPage()) {
          param.displayType = 1;
        }

        // お問い合わせ.
        if(this._controller.isContactPage()) {
          param.displayType = 2;
        }

        this._controller._apiCaller.get(ApiCaller.EndPoints.StoreSearch,param);
    }

}

// メニュー名称.
MenuManager.ClassName = {};
MenuManager.ClassName.None = "";
MenuManager.ClassName.Hamburger     = "gnav";
MenuManager.ClassName.StoreSearch   = "store";
MenuManager.ClassName.Maestro       = "maestro";
MenuManager.ClassName.ProductSearch = "search";
