import {ApiCaller} from "./api-caller";
import {MenuManager} from "./menu-manager";

export class Recommend {

    constructor(controller) {
        this._controller = controller;
        this._liBaseElement = null;
        this._recommends = [];
        this._limit = 4;
        this._offset = 0;
        this._maxLength = 12;
        this._isInitialized = false;
    }

    getIsInitialized() {
        return this._isInitialized;
    }

    setIsInitialized(isInitialized) {
        this._isInitialized = isInitialized;
    }


    initialize() {
        this.initializeElements();
        this.setupEvents();

        // SPは3件ずつ.
        if(this._controller.isSmartPhone()) {
            this._limit = 2;
        }
    }


    clearList() {
        $(".p-topRecommend .p-list__inner").empty();
    }


    initializeElements() {

        // $("#reserve_store_address_pref").empty();
        // CLONEのベースをコピーする。
        if(this._liBaseElement == null) {
            this._liBaseElement = $(".p-topRecommend .p-list__inner .c-product").eq(0).clone(true);
        }
        this.clearList();

    }

    setupEvents() {
        const _this = this;

        // マエストロレコメンドのもっと見るボタン.
        $(".maestro-more-button").on("click", function() {
            _this._offset += _this._limit;
            _this.updateRecommendList(_this._offset);
            if(_this._limit+_this._offset >= _this._maxLength) {
                $(this).hide();
            }
        });
    }


    setRecommendData(data) {
        this._offset = 0;
        this._recommends = data;
        this._maxLength = data.length;

        $(".maestro-more-button").show();
        // そもそも4件以内ならもっと見るボタンを非表示にする.
        if(this._controller.isSmartPhone() === false && this._maxLength <= 4) {
            $(".maestro-more-button").hide();
        }

        if(this._controller.isSmartPhone() === true && this._maxLength <= 2) {
            $(".maestro-more-button").hide();
        }
    }

    updateRecommendList(offset) {
        const _this = this;
        const data = this._recommends;

        for(let ii=offset;ii<offset+this._limit;ii++) {


            if(ii >= _this._maxLength) {
                break;
            }

            // 要素をコピー
            let baseElement = this._liBaseElement.clone(true);

            // 商品リンク
            baseElement.find(".c-productLink").attr("href", "/items/" + data[ii]["itemsColors_searchName"] + "/");

            // 画像.
            const imageName = ApiCaller.EndPointBase + "/img/sys/itemsColorsThumb/" + data[ii]["itemsColors_searchName"];
            // <div class="swiper-slide"><img src=""></div>
            baseElement.find(".c-productMain__thumb").append('<div class="swiper-slide"><img src="' + imageName + '_thumb.jpg" /></div>');
            baseElement.find(".c-productMain__thumb").append('<div class="swiper-slide"><img src="' + imageName + '_fl_thumb.jpg" /></div>');
            baseElement.find(".c-productMain__thumb").append('<div class="swiper-slide"><img src="' + imageName + '_l_thumb.jpg" /></div>');

            // <li class="is-current"><object><a href=""><img src="/img/dummy/1391-MARU-BR-3.jpg" alt="KATHARINE HAMNETT"></a></object></li>
            // baseElement.find(".c-productMain__pager ul").append('<li><object><a href="javascript:void(0);"><img src="'+imageName+'.jpg"></a></object></li>');
            // baseElement.find(".c-productMain__pager ul").append('<li><object><a href="javascript:void(0);"><img src="'+imageName+'_fl.jpg"></a></object></li>');
            // baseElement.find(".c-productMain__pager ul").append('<li><object><a href="javascript:void(0);"><img src="'+imageName+'_l.jpg"></a></object></li>');

            // ブランド.
            baseElement.find(".brand").text(data[ii]["brand_name"]);

            // 品番.
            baseElement.find(".name").text(data[ii]["items_name"]);

            // カラー品番.
            baseElement.find(".colors-name").html(data[ii]["itemsColors_name"] + "&nbsp;");

            // カラー名.
            baseElement.find(".item-colors-name").text(data[ii]["front_color"]);

            // 価格.
            baseElement.find(".c-productMain__priceYen").html("&yen;" + this._controller.number_format(data[ii]["items_price"]) + '<span class="c-productMain__priceTax">税込</span>');

            // マエストロカードに追加ボタン.
            baseElement.find(".web-try-on-button").attr("data-item-id", data[ii]["itemsColors_id"]);
            baseElement.find(".web-try-on-button").on("click", function() {
                const isAdded = _this._controller.toggleWebTryOn($(this).data("itemId"));
                if(isAdded) {
                    $(this).addClass("-checked");
                }else{
                    $(this).removeClass("-checked");
                }
            });

            // 既にリスト入りしている商品のクラス切り替え.
            if(this._controller.isAlreadyInList(data[ii]["itemsColors_id"])) {
                baseElement.find(".web-try-on-button").addClass("-checked");
            }

            // Swiper.
            setTimeout(function() {
                _this._controller.applySwiper(baseElement.find(".swiper-container"));
            }, 500);

            $(".p-topRecommend .p-list__inner").append(baseElement);
        }
    }
}


