import {ApiCaller} from "./api-caller";

export class ProductMenu {

    constructor(menuManager) {
        this._menuManager = menuManager;
        this._checkedTypeId = "";
        this._checkedGenderId = "";
        this._brandList = [];
        this.initialize();
    }

    initialize() {
        this.initializeElements();
        this.setupEvents();
    }

    initializeElements() {

        // ラジオボタンの初期値.
        this._checkedTypeId = $(".l-headerNav__li.search input[name=type_id]:checked").val();
        this._checkedGenderId = $(".l-headerNav__li.search input[name=gender_id]:checked").val();
        this.updateTypeCheck();

        $("#search_brand").empty();
        $("#search_price").empty();
        $(".c-menuform__dd.material").empty();
        $(".c-menuform__dd.frame").empty();
        $(".c-menuform__dd.frametype").empty();
        $(".c-menuform__dd.color").empty();
    }

    /***********************************************************************************************
     * 商品検索メニュー内イベント.
     ***********************************************************************************************/
    setupEvents() {
        const _this = this;

        /******************************************
         * タイプのトグル
         *******************************************/
        $(".l-headerNav__li.search input[name=type_id]").on("click", function () {
            if ($(this).val() === _this._checkedTypeId) {
                $(this).prop("checked", false);
                _this._checkedTypeId = "";
            } else {
                _this._checkedTypeId = $(this).val();
            }

            _this.updateTypeCheck();
        });


        /******************************************
         * 性別のトグル。
         ******************************************/
        $(".l-headerNav__li.search input[name=gender_id]").on("click", function () {
            if ($(this).val() === _this._checkedGenderId) {
                $(this).prop("checked", false);
                _this._checkedGenderId = "";
            } else {
                _this._checkedGenderId = $(this).val();
            }
        });


        /******************************************
         * 条件リセット.
         ******************************************/
        $(".l-headerNav__li.search button[type=reset]").on("click", function () {
            $(".l-headerNav__li.search input[name=type_id][value=1]").prop("checked", true);
            $(".l-headerNav__li.search input[name=gener_id]").prop("checked", false);
            $(".l-headerNav__li.search input[name^=frameshape_id]").prop("checked", false);
            $(".l-headerNav__li.search input[name^=frametype_id]").prop("checked", false);
            $(".l-headerNav__li.search input[name^=colors_id]").prop("checked", false);
            $(".l-headerNav__li.search input[name^=materialgroup_id]").prop("checked", false);
            $("#search_price").val("");
            $("#search_brand").val("");
            $("#search_keyword").val("");

            _this._checkedTypeId = 1;
            _this.updateTypeCheck();

            $(".l-headerNav__li.search .l-menu").animate({scrollTop: 0}, 400);
        });


        /********************************************
         * 検索実行.
         ********************************************/
        $(".l-headerNav__li.search button[type=submit]").on("click", function () {
            var query = {};

            // タイプ.
            // タイプは無選択も保存する.
            // if (_this._checkedTypeId !== "") {
                query["type_id"] = _this._checkedTypeId;
            // }

            // 性別.
            // 性別は無選択も保存する.
            // if (_this._checkedGenderId !== "") {
                query["gender_id"] = _this._checkedGenderId;
            // }

            // フレームシェイプ。
            if ($(".l-headerNav__li.search input[name^=frameshape_id]:checked").length > 0) {
                let frameshape_ids = [];
                $(".l-headerNav__li.search input[name^=frameshape_id]:checked").each(function () {
                    frameshape_ids.push($(this).val());
                })
                query["frameShape_id"] = frameshape_ids.join("_");
            }

            // フレームタイプ.
            // サングラスの場合は送らない.
            if (_this._checkedTypeId != ProductMenu.SunGlassTypeId && $(".l-headerNav__li.search input[name^=frametype_id]:checked").length > 0) {
                let frametype_ids = [];
                $(".l-headerNav__li.search input[name^=frametype_id]:checked").each(function () {
                    frametype_ids.push($(this).val());
                })
                query["frameType_id"] = frametype_ids.join("_");
            }

            // カラー.
            // サングラスの場合は送らない.
            if (_this._checkedTypeId != ProductMenu.SunGlassTypeId && $(".l-headerNav__li.search input[name^=colors_id]:checked").length > 0) {
                let colors_ids = [];
                $(".l-headerNav__li.search input[name^=colors_id]:checked").each(function () {
                    colors_ids.push($(this).val());
                })
                query["colors_id"] = colors_ids.join("_");
            }

            // 素材.
            // フレームシェイプ。
            if ($(".l-headerNav__li.search input[name^=materialgroup_id]:checked").length > 0) {
                let materialgroup_ids = [];
                $(".l-headerNav__li.search input[name^=materialgroup_id]:checked").each(function () {
                    materialgroup_ids.push($(this).val());
                })
                query["materialGroup_id"] = materialgroup_ids.join("_");
            }

            // 価格.
            if ($("#search_price").val() !== "") {
                query["price_id"] = $("#search_price").val();
            }

            // ブランド.
            if ($("#search_brand").val() !== "") {
                query["brand_id"] = $("#search_brand").val();
            }

            // フリーワード.
            if ($("#search_keyword").val() !== "") {
                query["freeword"] = $("#search_keyword").val();
            }

            let query_array = [];
            for(const key in query) {
                query_array.push(key + "=" + encodeURIComponent(query[key]));
            }
            location.href = ApiCaller.EndPointBase + "/items/?" +query_array.join("&");

            return false;
        });

    }


    /***************************************************************************************
     * タイプ選択の更新（サングラス用)
     ****************************************************************************************/
    updateTypeCheck() {
        if (this._checkedTypeId == ProductMenu.SunGlassTypeId) {
            $(".l-headerNav__li.search .c-menuform__dl.color").hide();
            $(".l-headerNav__li.search .c-menuform__dl.frame-type").hide();
        } else {
            $(".l-headerNav__li.search .c-menuform__dl.color").show();
            // $(".l-headerNav__li.search .c-menuform__dl.frame-type").show();
            $(".l-headerNav__li.search .c-menuform__dl.frame-type").hide();
        }
        this.updateBrandPulldown($("select[name=search_brand]").val());
    }

    /*****************************************************************************************
     * タイプ選択の結果によってブランドプルダウンの中身を変更.
     ****************************************************************************************/
    updateBrandPulldown(checkedId) {

        if(typeof checkedId === "undefined") {
            checkedId = "";
        }

        $("#search_brand").empty();
        $("#search_brand").append('<option value="" selected="selected">指定しない</option>');
        for (let ii = 0; ii < this._brandList.length; ii++) {
            // 選択肢を生成.
            if(this._checkedTypeId === "" || $.inArray(this._checkedTypeId.toString(), this._brandList[ii].brand_glasses_types) !== -1) {
                let option = $("<option>");
                option.data("brand_glasses_types", this._brandList[ii].brand_glasses_types);
                option.attr("value", this._brandList[ii].brand_id);
                option.text(this._brandList[ii].brand_name);

                // 選択状態の更新.
                if(this._brandList[ii].brand_id == checkedId) {
                    option.prop("selected", true);
                }

                $("#search_brand").append(option);
            }
        }
    }


    /*****************************************************************************************
     * 検索条件選択の初期化
     * @param conditions
     ****************************************************************************************/
    initializeSearchCondition(conditions) {

        // ブランド.
        const brandList = conditions.brand;
        this._brandList = brandList;
        $("#search_brand").append('<option value="" selected="selected">指定しない</option>');
        for (let ii = 0; ii < brandList.length; ii++) {
            // 選択肢を生成.
            let option = $("<option>");
            option.data("brand_glasses_types", brandList[ii].brand_glasses_types);
            option.attr("value", brandList[ii].brand_id);
            option.text(brandList[ii].brand_name);
            $("#search_brand").append(option);
        }

        // 価格.
        const priceList = conditions.price;
        $("#search_price").append('<option value="" selected="selected">指定しない</option>');
        for (let ii = 0; ii < priceList.length; ii++) {
            // 選択肢を生成.
            let option = $("<option>");
            option.attr("value", priceList[ii].price_id);
            option.text(priceList[ii].price_name);
            $("#search_price").append(option);
        }

        // 素材.
        const materialList = conditions.materialgroup;
        for (let ii = 0; ii < materialList.length; ii++) {
            // 選択肢を生成.
            let span = $('<span class="c-checkboxRound">');
            let input = $('<input type="checkbox" name="materialgroup_id[]" id="materialgroup_' + materialList[ii].materialgroup_id + '" value="' + materialList[ii].materialgroup_id + '">');
            let label = $('<label for="materialgroup_' + materialList[ii].materialgroup_id + '">' + materialList[ii].materialgroup_name + '</label>');
            span.append(input);
            span.append(label);
            $(".c-menuform__dd.material").append(span);
        }

        // フレームシェイプ.
        const frameShapeList = conditions.frameshape;
        for (let ii = 0; ii < frameShapeList.length; ii++) {
            // 選択肢を生成.
            let span = $('<span class="c-checkboxRound">');
            let input = $('<input type="checkbox" name="frameshape_id[]" id="frameshape_' + frameShapeList[ii].frameshape_id + '" value="' + frameShapeList[ii].frameshape_id + '">');
            let label = $('<label for="frameshape_' + frameShapeList[ii].frameshape_id + '"><img src="/img/common/icon_fr_' + frameShapeList[ii].frameshape_id + '.png" alt="' + frameShapeList[ii].frameshape_name + '">' + frameShapeList[ii].frameshape_name + '</label>');
            span.append(input);
            span.append(label);
            $(".c-menuform__dd.frame").append(span);
        }

        // フレームタイプ.
        const frameTypeList = conditions.frametype;
        for (let ii = 0; ii < frameTypeList.length; ii++) {
            // 選択肢を生成.
            let span = $('<span class="c-checkboxRound">');
            let input = $('<input type="checkbox" name="frametype_id[]" id="frametype_' + frameTypeList[ii].frametype_id + '" value="' + frameTypeList[ii].frametype_id + '">');
            let label = $('<label for="frametype_' + frameTypeList[ii].frametype_id + '"><img src="/img/common/icon_fr_type_' + frameTypeList[ii].frametype_id + '.png" alt="' + frameTypeList[ii].frametype_name + '">' + frameTypeList[ii].frametype_name + '</label>');
            span.append(input);
            span.append(label);
            $(".c-menuform__dd.frametype").append(span);
        }

        // カラー.
        const colorList = conditions.colors;
        /*
        <span class="c-checkboxRound">
          <input type="checkbox" id="color_1" name="color_1" value="">
          <label for="color_1"><span class="silver">シルバー</span></label>
        </span>
         */
        for (let ii = 0; ii < colorList.length; ii++) {
            // 選択肢を生成.
            let span = $('<span class="c-checkboxRound">');
            let input = $('<input type="checkbox" name="colors_id[]" id="colors_' + colorList[ii].colors_id + '" value="' + colorList[ii].colors_id + '">');
            let label = $('<label for="colors_' + colorList[ii].colors_id + '"><span class="silver"><img src="/img/common/icon_color_'+colorList[ii].colors_id+'.svg" alt="'+colorList[ii].colors_name+'">' + colorList[ii].colors_name + '</span></label>');
            span.append(input);
            span.append(label);
            $(".c-menuform__dd.color").append(span);
        }

    }


    /*****************************************************************************************
     * GETクエリから検索条件を復元する.
     ****************************************************************************************/
    restoreSearchConditionFromQuery() {
        // ?type_id=2&gender_id=2&frameShape_id=3_5_7&frameType_id=1_3&materialGroup_id=1&price_id=30000&brand_id=24&freeword=てｓｔ
        const type_id   = location.href.match(/type_id=(\d+)/);
        const gender_id = location.href.match(/gender_id=(\d+)/);
        const frameShape_id = location.href.match(/frameShape_id=([\d_]+)/);
        const frameType_id = location.href.match(/frameType_id=([\d_]+)/);
        const colors_id = location.href.match(/colors_id=([\d_]+)/);
        const materialGroup_id = location.href.match(/materialGroup_id=([\d_]+)/);
        const price_id = location.href.match(/price_id=([\d]+)/);
        const brand_id = location.href.match(/brand_id=([\d]+)/);
        const freeword = location.href.match(/freeword=([^\&]+)/);
        let checked_brand_id = "";

        if(type_id != null) {
            $("input[type=radio][name=type_id][value="+type_id[1]+"]").prop("checked", true);
            this._checkedTypeId = type_id[1];
        }else{
            // type_idは無選択も保存する.
            if(location.href.match(/type_id=/) != null) {
                $("input[type=radio][name=type_id]").prop("checked", false);
                this._checkedTypeId = "";
            }
        }
        
        if(gender_id != null) {
            $("input[type=radio][name=gender_id][value="+gender_id[1]+"]").prop("checked", true);
            this._checkedGenderId = gender_id[1];
        }else{
            // gender_idは無選択も保存する.
            if(location.href.match(/gender_id=/) != null) {
                $("input[type=radio][name=gender_id]").prop("checked", false);
                this._checkedGenderId = "";
            }

        }
        
        if(frameShape_id != null) {
            let tmp = frameShape_id[1].split(/_/);
            for(let ii=0;ii<tmp.length;ii++) {
                $("input[type=checkbox][name^=frameshape_id][value="+tmp[ii]+"]").prop("checked", true);
            }
        }

        if(frameType_id != null) {
            let tmp = frameType_id[1].split(/_/);
            for(let ii=0;ii<tmp.length;ii++) {
                $("input[type=checkbox][name^=frametype_id][value="+tmp[ii]+"]").prop("checked", true);
            }
        }

        if(colors_id != null) {
            let tmp = colors_id[1].split(/_/);
            for(let ii=0;ii<tmp.length;ii++) {
                $("input[type=checkbox][name^=colors_id][value="+tmp[ii]+"]").prop("checked", true);
            }
        }

        if(materialGroup_id != null) {
            let tmp = materialGroup_id[1].split(/_/);
            for(let ii=0;ii<tmp.length;ii++) {
                $("input[type=checkbox][name^=materialgroup_id][value="+tmp[ii]+"]").prop("checked", true);
            }
        }
        
        if(price_id != null) {
            $("select[name=search_price]").find("option[value="+price_id[1]+"]").prop("selected", true);
        }
        if(brand_id != null) {
            $("select[name=search_brand]").find("option[value="+brand_id[1]+"]").prop("selected", true);
            checked_brand_id = brand_id[1];
        }
        if(freeword != null) {
            $("input[name=search_keyword]").val(decodeURIComponent(freeword[1]));
        }

        // ブランドプルダウンを更新.
        this.updateBrandPulldown(checked_brand_id);
    }
}

ProductMenu.NormalGlassTypeId = 1;
ProductMenu.SunGlassTypeId = 2;