import {Reserve} from "./reserve";

export class ContactUs extends Reserve {

  initializeElements() {

    // 来店予約の初期化.
    super.initializeElements();

    // お問い合わせの初期化.
    this.updateShopBlock($("#contact_use_shop_type").val(), 1);
  }

  setupEvents() {

    // 来店予約のイベント.
    super.setupEvents();

    // お問い合わせのイベント.
    const _this = this;
    $("#contact_use_shop_type").on("change", function() {
      const value = $(this).val();
      _this.updateShopBlock(value);
    })
  }



  updateShopBlock(value, slideDuration = 500) {
    /*
      仕様メモ：
      選択してください段階では、
      「都道府県」〜「お問い合わせ内容」まで閉じておく。

      「利用店舗あり」を選択
      ↓↓
      「都道府県」〜「お問い合わせ内容」が表示される。

      「利用店舗なし」「不明」を選択
      ↓↓
      「お問い合わせ内容」だけが表示される。
     */

    if(value == "") {
      $(".prefecture-select-box").slideUp(slideDuration);
      $(".city-select-box").slideUp(slideDuration);
      $(".filter-button-box").slideUp(slideDuration);
      $(".c-storeResult").slideUp(slideDuration);
      // $(".contact_customer_number-box").slideUp(slideDuration);
      $(".contact_note-box").slideUp(slideDuration);
    }else if(value == "1") {
      $(".prefecture-select-box").slideDown(slideDuration);
      $(".city-select-box").slideDown(slideDuration);
      $(".filter-button-box").slideDown(slideDuration);
      $(".c-storeResult").slideDown(slideDuration);
      // $(".contact_customer_number-box").slideDown(slideDuration);
      $(".contact_note-box").slideDown(slideDuration);
    }else if(value == "2" || value == "3") {
      $(".prefecture-select-box").slideUp(slideDuration);
      $(".city-select-box").slideUp(slideDuration);
      $(".filter-button-box").slideUp(slideDuration);
      $(".c-storeResult").slideUp(slideDuration);
      // $(".contact_customer_number-box").slideUp(slideDuration);
      $(".contact_note-box").slideDown(slideDuration);
    }

  }


}
