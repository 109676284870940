import {ApiCaller} from "./api-caller";
import {MenuManager} from "./menu-manager";

export class CardMenu {

    constructor(menuManager) {
        this._menuManager = menuManager;
        this._wrapper = $(".l-headerNav__li.maestro");
        this.initialize();
    }

    initialize() {
        this.initializeElements();
        this.setupEvents();
    }

    initializeElements() {
        this._wrapper.find(".hdMaestro.before").hide();
        this._wrapper.find(".hdMaestro.after").hide();

        /////// 試着リストのベースHTML.
        this._listBaseElement = $('<li class="maestroProduct__item">\n' +
            '\t\t\t\t\t\t\t\t\t\t\t<div class="maestroProduct__del"><button type="button" class="try-on-delete-button"><span>削除</span></button></div>\n' +
            '\t\t\t\t\t\t\t\t\t\t\t<div class="maestroProduct__body">\n' +
            '\t\t\t\t\t\t\t\t\t\t\t\t<div class="maestroProduct__thumb">\n' +
            '\t\t\t\t\t\t\t\t\t\t\t\t\t<span><img src="" class="list-product-image"></span>\n' +
            '\t\t\t\t\t\t\t\t\t\t\t\t</div>\n' +
            '\t\t\t\t\t\t\t\t\t\t\t\t<div class="maestroProduct__text">\n' +
            '\t\t\t\t\t\t\t\t\t\t\t\t\t<div class="maestroProduct__title">KATHARINE HAMNETT</div>\n' +
            '\t\t\t\t\t\t\t\t\t\t\t\t\t<div class="maestroProduct__spec"><span class="product-id">02-0033</span><span class="pc"> / </span><span class="product-color">2 ブラウン</span></div>\n' +
            '\t\t\t\t\t\t\t\t\t\t\t\t</div>\n' +
            '\t\t\t\t\t\t\t\t\t\t\t\t<div class="maestroProduct__price"><span class="maestroProduct__priceYen">&yen;13,800</span><span class="maestroProduct__priceTax">税込</span></div>\n' +
            '\t\t\t\t\t\t\t\t\t\t\t</div>\n' +
            '\t\t\t\t\t\t\t\t\t\t</li>');

        this._listEmptyElement = $('<li class="maestroProduct__item"><div class="maestroProduct__noItem">未登録</div></li>');

    }

    /*********************************************************************************************
     * ユーザ情報APIの返答値から、メニューの内容を更新.
     * このメソッドがコールされた時点で、this._menuManager._controller._userInfoが存在することが保証されている.
     * @param userInfo
     *********************************************************************************************/
    updateMenuStateByUserInfo(userInfo) {

        if(userInfo["maestroCard"] != null) {
            this.updateTryOnList();
            this._wrapper.find(".hdMaestro.after").show();

            // PC処理.
            if(this._menuManager._controller.isSmartPhone() === false) {
                // カード未作成メッセージを非表示.
                this._wrapper.find(".l-headerNav__cardState").hide();
                // バッジの○を非表示.
                this._wrapper.find(".l-headerNav__icon").hide();

                // カード作成後一度もメニューを開いていなければ開く.
                if(this._menuManager._controller._storage.getDisplayMaestroMenu() === false) {
                    // this._menuManager.toggleMenuAt(MenuManager.ClassName.Maestro);
                    this._menuManager._controller._storage.saveDisplayMaestroMenu();
                }
            }else{
            // SP処理.
                // カード作成後一度もメニューを開いていなければ、吹き出しテキストを変える..
                // if(this._menuManager._controller._storage.getDisplayMaestroMenu() === false) {
                // コントロールしない 2021年9月28日.
                if(false) {
                    $(".l-headerNav__cardState").text("カード作成済みです");
                    // カード未作成メッセージを表示.
                    this._wrapper.find(".l-headerNav__cardState").show();
                    // バッジの○を表示.
                    this._wrapper.find(".l-headerNav__icon").show();
                }else{
                    // カード未作成メッセージを非表示.
                    this._wrapper.find(".l-headerNav__cardState").hide();
                    // バッジの○を非表示.
                    this._wrapper.find(".l-headerNav__icon").hide();

                }

            }

        }else{

            // WEB試着リストが存在する場合は更新する.
            this.updateTryOnList();
            this._wrapper.find(".hdMaestro.before").show();
            $(".l-container").addClass("-nocard");

            // カード未作成メッセージ、バッジの〇は表示させない.
            // 2021年9月28日

            // カード未作成メッセージを表示.
            // this._wrapper.find(".l-headerNav__cardState").show();
            // バッジの○を表示.
            // this._wrapper.find(".l-headerNav__icon").show();
        }

        // マエストロカード作成の選択肢は強制的に表示する.
        // 2021年9月28日.
        this._wrapper.find(".hdMaestro.after").show();
        this._wrapper.find(".hdMaestro.before").hide();
    }

    openCardMenuCall() {
        if(this._menuManager._controller._userInfo.maestroCard != null) {
            this._menuManager._controller._storage.saveDisplayMaestroMenu();
            // カード未作成メッセージを非表示.
            this._wrapper.find(".l-headerNav__cardState").hide();
            // バッジの○を非表示.
            this._wrapper.find(".l-headerNav__icon").hide();
        }

        // WEBで試す用のリンク設定.
        const userInfo = this._menuManager._controller._userInfo;

        let items = [];
        for(let ii=0;ii<userInfo.tryOnList.items.length;ii++) {
            items.push(userInfo.tryOnList.items[ii].itemsColors_searchName);
        }

        let query = "/web-try-on/?item[]=" + items.join("&item[]=");
        $(".web-try-on-menu-button").attr("href", query);

    }

    updateTryOnList() {
        const _this = this;
        const tryOnList = this._menuManager._controller._userInfo.tryOnList;
        const len = tryOnList.items.length;

        // リストに商品が存在する場合、hdMaestro__noProductsを非表示にしてリストを表示する。
        if(tryOnList != null && len > 0) {
            this._wrapper.find(".hdMaestro__noProducts").hide();
            this._wrapper.find(".hdMaestro__note").hide();
            this._wrapper.find(".maestroProduct").show();

            // リストを空で初期化.
            this._wrapper.find(".maestroProduct__list").empty();

            for (let ii = 0; ii < len; ii++) {
                let baseElement = this._listBaseElement.clone(true);

                // 画像.
                const imageUrl = ApiCaller.EndPointBase + "/img/sys/itemsColors/" + tryOnList["items"][ii]["itemsColors_searchName"] + ".jpg";
                baseElement.find(".list-product-image").attr("src", imageUrl);

                // ブランド名.
                baseElement.find(".maestroProduct__title").text(tryOnList["items"][ii]["brand_name"]);

                // 品番.
                baseElement.find(".product-id").text(tryOnList["items"][ii]["items_name"]);

                // カラー.
                baseElement.find(".product-color").text(tryOnList["items"][ii]["itemsColors_name"] + " " + tryOnList["items"][ii]["front_color"]);

                // 価格.
                baseElement.find(".maestroProduct__priceYen").html("&yen;" + this._menuManager._controller.number_format(tryOnList["items"][ii]["items_price"]));

                // 削除ボタン.
                baseElement.find(".try-on-delete-button").data("itemsColors_id", tryOnList["items"][ii]["itemsColors_id"]);
                baseElement.find(".try-on-delete-button").on("click", function () {
                    const itemsColors_id = $(this).data("itemsColors_id");
                    _this._menuManager._controller.deleteWebTryOnList(itemsColors_id);
                    _this.showModal("削除しました");
                });

                this._wrapper.find(".maestroProduct__list").append(baseElement);
            }

            for (let ii = len; ii < 3; ii++) {
                let emptyElement = this._listEmptyElement.clone(true);
                this._wrapper.find(".maestroProduct__list").append(emptyElement);
            }
        }else{
            // リストに商品が存在しない場合、hdMaestro__noProductsを表示する
            this._wrapper.find(".hdMaestro__noProducts").show();
            this._wrapper.find(".hdMaestro__note").show();
            this._wrapper.find(".maestroProduct").hide();
        }
    }



    /***********************************************************************************************
     * マエストロカードメニュー内イベント.
     ***********************************************************************************************/
    setupEvents() {
        const _this = this;

        /**
         * マエストロカードを作るボタン(未作成時のみ.)
         */
        this._wrapper.find(".create-maestro-card-button").on("click", function() {
            _this._menuManager._controller._storage.clearMaestroSkipped();
            location.href = "/";
        });


        /**
         * メニュー：モーダル閉じるボタン.
         */
        this._wrapper.find(".maestroProduct__deleted button").on("click", function() {
            _this.hideModal();
        });


        /**
         * WEBで試すボタン.
         */
        this._wrapper.find(".web-try-on-menu-button").on("click", function() {
           return _this.navigateWebTryOn();
        });

    }

    showModal(message, isMaestro = false) {
        this._wrapper.find(".maestroProduct__deleted p").html(message);
        $(".l-container").addClass("-deleted");
        if(isMaestro) {
          $(".maestro-check-button").show();
        }else{
          $(".maestro-check-button").hide();
        }
    }

    hideModal() {
        $(".l-container").removeClass("-deleted");
    }

    navigateWebTryOn() {

        // PCはモーダル開く.
        if(this._menuManager._controller.isSmartPhone() === false) {
            $(".l-containerWrap").css("opacity", 0);
            $(".generator-alert-modal").addClass("-show");
            return false;
        }

        return true;
    }
}


