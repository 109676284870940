export class StoreMenu {
    constructor(menuManager) {
        this._menuManager = menuManager;
        this._prefData = [];
        this._storeListBaseElement = null;
        this._searchConditionText = "";
        this.initialize();
    }

    initialize() {
        this.initializeElements();
        this.setupEvents();
    }

    initializeElements() {

        // ベースエレメントをコピーし保持.
        if(this._storeListBaseElement == null) {
            this._storeListBaseElement = $(".l-headerNav__li.store .c-storeList .c-storeList__item").eq(0).clone(true);
        }

        $(".l-headerNav__li.store .hdStore__resultTitle").text("");
        $(".l-headerNav__li.store .hdStore__resultNom").text("");
        $(".l-headerNav__li.store .c-storeList").empty();
        $(".l-headerNav__li.store .hdStore__result").hide();
    }

    setupEvents() {
        const _this = this;
        /***********************************************************************************************
         * ショップ検索メニュー内イベント.
         ***********************************************************************************************/
        $("#store_address_pref").on("change", function() {
            _this.updateCitySelect($(this).val());
        });

        $(".c-menuform.store button").on("click", function(e) {

            e.preventDefault();

            // 検索条件を保存.
            _this.saveSearchConditionText();

            let param = {};
            param["pref_id"]        = $("#store_address_pref").val();
            param["city_id"]        = $("#store_address_city").val();
            param["contact_flag"]   = $("#hdStoreContact").prop("checked");
            _this._menuManager.doStoreSearch(param["pref_id"], param["city_id"], param["contact_flag"]);
            return false;
        })

    }

    saveSearchConditionText() {
        let conditions = [];

        // 都道府県。
        if($("#store_address_pref").val() !== "") {
            conditions.push($("#store_address_pref option:selected").text());
        }

        // 町名.
        if($("#store_address_city").val() !== "") {
            conditions.push($("#store_address_city option:selected").text());
        }

        // コンタクト.
        if($("#hdStoreContact").prop("checked")) {
            conditions.push("コンタクトレンズ取扱い店");
        }

        if(conditions.length === 0) {
            this._searchConditionText = "検索条件なし";
        }else{
            this._searchConditionText = conditions.join("、");
        }
    }

    pickupPrefDataByPrefectureItemId(pref_id) {
        for(let ii=0;ii<this._prefData.length;ii++) {
            if(this._prefData[ii]["prefectureItem_id"] === pref_id) {
                return this._prefData[ii];
            }
        }

        return null;
    }

    /*********************************************************************************
     * 店舗検索の都道府県初期化.
     *********************************************************************************/
    initializeStorePrefecture(data) {

        this._prefData = data["pref"];

        if(typeof $("#store_address_pref").data("initialized") === "undefined") {
            for(let ii=0;ii<this._prefData.length;ii++) {

                let option = $("<option>");
                option.attr("value", this._prefData[ii]["prefectureItem_id"]);
                option.text(this._prefData[ii]["prefectureItem_name"]);
                $("#store_address_pref").append(option);
            }

            $("#store_address_pref").data("initialized", true);
        }
    }


    /*********************************************************************************
     * 店舗検索の市区町村初期化.
     *********************************************************************************/
    updateCitySelect(pref_id) {
        const prefectureData = this.pickupPrefDataByPrefectureItemId(pref_id);

        // 初期化.
        $("#store_address_city").empty();
        $("#store_address_city").append('<option value="">市区町村を選択してください</option>')

        // 都道府県データがなければ戻る.
        if(prefectureData == null){
            return false;
        }

        for(let ii=0;ii<prefectureData["city"].length;ii++) {
            let option = $("<option>");
            option.attr("value", prefectureData["city"][ii]["city_id"]);
            option.text(prefectureData["city"][ii]["city_name"]);
            $("#store_address_city").append(option);
        }
    }

    /*********************************************************************************
     * 店舗リストの表示.
     *********************************************************************************/
    updateStoreList(shopList) {

        // 検索結果エリアを表示
        $(".l-headerNav__li.store .hdStore__result").show();

        // 検索条件表示.
        $(".l-headerNav__li.store .hdStore__resultTitle").text(this._searchConditionText);
        $(".l-headerNav__li.store .hdStore__resultNom").text(shopList.length + "件");

        // リストを空にする.
        $(".l-headerNav__li.store .c-storeList").empty();

        // 要素生成.
        const len = shopList.length;
        for(let ii=0;ii<len;ii++) {
            let tmpBase = this._storeListBaseElement.clone(true);

            // 情報設定.
            tmpBase.find(".storeInfo__title a").text(shopList[ii].shop_name);
            tmpBase.find(".storeInfo__title a").attr("href", "/shop/" + shopList[ii].shop_no + "/");
            tmpBase.find(".storeInfo__title a").attr("target", "_blank");
            tmpBase.find(".storeInfo__text.address").html("〒" + shopList[ii].shop_zip + "<br>" + shopList[ii].prefectureItem_name + shopList[ii].city_name + shopList[ii].shop_address);
            tmpBase.find(".storeInfo__text.tel").text(shopList[ii].shop_tel);
            tmpBase.find(".storeInfo__text.open-hour").text(shopList[ii].shop_beginTime + "〜" + shopList[ii].shop_endTime);
            tmpBase.find(".storeInfo__text.holiday").text(shopList[ii].shop_holiday);
            if(shopList[ii].shop_facility !== "") {
                tmpBase.find(".storeInfo__text.facility").text(shopList[ii].shop_facility);
            }else{
                tmpBase.find(".storeInfo__text.facility").parent().remove();
            }
            tmpBase.find(".storeInfo__storeNo").text("店舗 No：" + shopList[ii].shop_no);

            // 駐車場.
            if(shopList[ii].shop_parkingFlag === "2") {
                tmpBase.find(".storeInfo__store li.parking").remove();
            }
            // 提携駐車場でない場合
            else if(shopList[ii].shop_parkingFlag === "1") {
                //<img src="/img/common/icon_parking.svg" alt="提携駐車場" class="storeInfo__storeIcon"><span class="storeInfo__storeText">提携駐車場<br>あり</span>
                tmpBase.find(".storeInfo__store li.parking img").attr("alt", "駐車場");
                tmpBase.find(".storeInfo__store li.parking img").attr("src", "/img/common/icon_parking_blue.svg");
                tmpBase.find(".storeInfo__store li.parking .storeInfo__storeText").html("駐車場<br>あり");
                tmpBase.find(".storeInfo__store li.parking .storeInfo__storeText").addClass("blue");
            }

            // コンタクト.
            if(shopList[ii].shop_clFlag !== "1") {
                tmpBase.find(".storeInfo__store li.contact-lens").remove();
            }

            $(".l-headerNav__li.store .c-storeList").append(tmpBase);
        }
    }
}
