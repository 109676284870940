import {MenuManager} from './menu-manager';
import {CardManager} from './card-manager';
import {ApiCaller} from './api-caller';
import {ItemList} from './item-list';
import {Reserve} from './reserve';
import {Recommend} from './recommend';
import {Storage} from './storage';
import {ContactUs} from "./contactus";

/********************************************************************************************************************************************
 * 全体のコントローラクラス.
 *********************************************************************************************************************************************/
class Controller {
    constructor() {
        this._storage = null;
        this._apiCaller = null;
        this._menuManager = null;
        this._userInfo = null;
    }

    /***************************************************************************
     * 初期化.
     ***************************************************************************/
    initialize() {

        // ストレージオブジェクト.
        this._storage = new Storage();

        // メニュー制御オブジェクト.
        this._menuManager = new MenuManager(this);
        this._menuManager.initialize();

        // カード制御オブジェクト.
        this._cardManager = new CardManager(this._storage, this);
        this._cardManager.initialize();

        // API呼び出しオブジェクト.
        this._apiCaller = new ApiCaller(this._storage, this);
        this._apiCaller.addEventListener(this);
        this._apiCaller.getUserInfo();


        if(this.isReservedPage()) {
          // 来店予約.
          this._apiCaller.getStorePrefectureList(2);
        }else if(this.isContactPage()) {
          // 問い合わせ.
          this._apiCaller.getStorePrefectureList(1);
        }else{
          // その他全て
          this._apiCaller.getStorePrefectureList(0);
        }

        this._apiCaller.getProductSearchCondition();

        // アイテムリスト.
        this._itemList = null;

        // 来店予約.
        this._reserve = null;

        // お問い合わせ.
        this._contactUs = null;

        // レコメンド
        this._recommend = null;

        // URLによって処理.
        this.dispatch();

        // 共通のイベント。
        this.setupCommonEvents();
        // debug.
        // this._apiCaller.postWebTryOn(16);

        // rename maestro atag.
        this.renameAddMaestroCardLink();
    }

    isReservedPage() {
      const pathname = location.pathname;
      if(pathname === "/10_reserve.html" || pathname === "/reserved.php" || pathname === "/reservedShop.php") {
        return true;
      }
      return false;
    }

    isContactPage() {
      const pathname = location.pathname;
      if(pathname.match(/\/contactus/) != null) {
        return true;
      }
      return false;
    }

    renameAddMaestroCardLink() {
      $("a").each(function() {
        var text = $(this).text();
        if(text === "マエストロカードに追加") {
          $(this).text("お気に入りに追加する");
        }
      })
    }


    setupCommonEvents() {

        const _this = this;

        // アラートモーダルの閉じる.
        $(".-verA .js-modalClose, .-verA .js-modal__closeBtn").on("click", function() {
            $(".l-containerWrap").css("opacity", "");
            $(".-verA").removeClass("-show");
            return false;
        })
        $(".-verB .js-modalClose, .-verB .js-modal__closeBtn").on("click", function() {
            $(".l-containerWrap").css("opacity", "");
            $(".-verB").removeClass("-show");
            return false;
        })
        $(".js-modal__closeLink").on("click", function() {
            $(".l-containerWrap").css("opacity", "");
            $(".js-modal").removeClass("-show");
            return false;
        })


        // ページ内リンクボタン.
        $('a[href^="#"]').click(function(){
            var speed = 500;
            var href= $(this).attr("href");
            var target = $(href == "#" || href == "" ? 'html' : href);
            var position = target.offset().top;

            // #top以外はヘッダの高さを考慮する.
            if(href !== "#top" && _this.isSmartPhone() === false) {
                position -= $("header").height();
            }

            $("html, body").animate({scrollTop:position}, speed, "swing");
            return false;
        });
    }

    /***************************************************************************
     * URLによって処理を分ける.
     ***************************************************************************/
    dispatch() {
        const url = location.href;
        const pathname = location.pathname;

        // TOP
        if(pathname === "/" || pathname === "/index.html" || pathname === "/index.php") {
            this._recommend = new Recommend(this);
            this._recommend.initialize();
            this.setupTopMovie();
        }

        // 商品一覧.
        if (pathname === "/items/" || pathname === "/items/index.php" || pathname === "/items/index.html" || pathname === "/mydo/" || pathname === "/mydo/index.html" || pathname === "/taflex/" || pathname === "/taflex/index.html" || pathname === "/cocoroto/" || pathname === "/cocoroto/index.html" || pathname === "/a.tokyo/" || pathname === "/a.tokyo/index.html" || pathname === "/nyan/" || pathname === "/nyan/index.html" || pathname === "/ktaiflex/" || pathname === "/ktaiflex/index.html" || pathname === "/remode/" || pathname === "/remode/index.html" || pathname === "/rayban/" || pathname === "/rayban/index.html" || pathname === "/oakley/" || pathname === "/oakley/index.html" || pathname === "/gentlegaze/" || pathname === "/gentlegaze/index.html") {
            this._itemList = new ItemList(this);
            this._itemList.initialize();

        }

        // 詳細.
        if(pathname.match(/^\/items\/\w+?-\w+?-\w+?(-\w+)?\.html$/) != null || pathname.match(/^\/items\/\w+?-\w+?-\w+?(-\w+)?\/?$/) != null) {
            this._itemList = new ItemList(this);
            this._itemList.initialize();
            this._itemList.overrideWebTryOnNavigate();

        }

        // 来店予約.
        if(this.isReservedPage()) {
            // 確認ページの場合は何もしない.
            if($("input[type='hidden'][name='_action'][value='confirm']").length !== 0) {
                return false;
            }

            this._reserve = new Reserve(this);
            this._reserve.initialize();
        }

        // お問い合わせ.
        if(this.isContactPage()) {
          $(".p-static__sub .p-static__subNav__item").eq(0).addClass("-current");

          // 確認ページの場合は何もしない.
          if(pathname.match(/\/contactus\/confirm/) != null || $("input[type='hidden'][name='_action'][value='confirm']").length !== 0) {
            return false;
          }

          this._contactUs = new ContactUs(this);
          this._contactUs.initialize();
        }
        // 会社概要.
        if(pathname === "/overview/") {
            $(".p-static__sub .p-static__subNav__item").eq(1).addClass("-current");
        }
        // サイトポリシー.
        if(pathname === "/site-policy/") {
            $(".p-static__sub .p-static__subNav__item").eq(2).addClass("-current");
        }
        // プライバシーポリシー.
        if(pathname === "/privacy-policy/") {
            $(".p-static__sub .p-static__subNav__item").eq(3).addClass("-current");
        }
    }


    /***************************************************************************
     * APIレスポンスのコールバック.
     * @param event
     * @param end_point
     * @param data
     * @param method string(GET, POST, PUT, DELETE)
     ***************************************************************************/
    notify(event, end_point, data, method) {
        // console.log(event, end_point, data, method);

        switch (event) {

            case ApiCaller.Event.ApiResponseError:

                /**************************************************************************
                 * ユーザー情報取得エラー.
                 ***************************************************************************/
                if (end_point === ApiCaller.EndPoints.GetUserInfo) {

                    // ユーザー情報が取得できない場合は、ローカルストレージの情報もクリアする.
                    this._storage.clearAll();

                    // 空のAPIレスポンスをエミュレートしてコールバックを呼び出す.
                    this.notify(ApiCaller.Event.ApiResponse, ApiCaller.EndPoints.GetUserInfo,{USER_ID:null, maestroCard:null, tryOnList: {is_deleted:false, items:[]}});
                    return;

                }
                /**************************************************************************
                 * WEB試着登録エラー.
                 ***************************************************************************/
                else if (end_point.indexOf(ApiCaller.EndPoints.WebTryOn) !== -1 && method === "post") {
                    if(typeof data.errors !== "undefined") {
                        let errorText = data.errors.join("<br>");
                        this.showAlertModal("　", errorText);
                    }
                }

                break;


            case ApiCaller.Event.ApiResponse:

                /**************************************************************************
                 * ユーザー情報取得完了.
                 ***************************************************************************/
                if (end_point === ApiCaller.EndPoints.GetUserInfo) {
                    this._userInfo = data;
                    this._storage.saveUserId(data);
                    this._storage.updateMaestroCardWithServerResponse(data);
                    this._cardManager.judgeModalOpen();
                    this._menuManager._cardMenu.updateMenuStateByUserInfo(data);
                    this.judgeIsDeletedTryOn(data);

                    // ユーザ情報の取得が完了してから、マエストロカードの準備をする.
                    this._apiCaller.getMaestroCardQuestion();

                    // ユーザ情報の取得が完了してから、レコメンドを取得する
                    if(this._recommend != null && this._recommend.getIsInitialized() === false) {
                        this._apiCaller.getRecommendedItems();
                        this._recommend.setIsInitialized(true);
                    }
                }
                /**************************************************************************
                 * 都道府県JSONの取得完了.
                 ***************************************************************************/
                else if (end_point === ApiCaller.EndPoints.StorePrefJson || end_point === ApiCaller.EndPoints.StorePrefJsonReserved || end_point === ApiCaller.EndPoints.StorePrefJsonContact) {
                    this._menuManager._storeMenu.initializeStorePrefecture(data);

                    // 来店予約オブジェクトがnullでなければ初期化する.
                    if(this._reserve != null) {
                        this._reserve.initializeStorePrefecture(data);
                    }

                    // 問い合わせオブジェクトがnullでなければ初期化する.
                    if(this._contactUs != null) {
                      this._contactUs.initializeStorePrefecture(data);
                    }
                }
                /**************************************************************************
                 * 店舗検索JSONの取得完了.
                 ***************************************************************************/
                else if (end_point === ApiCaller.EndPoints.StoreSearch) {
                    this._menuManager._storeMenu.updateStoreList(data.shops);
                }
                /**************************************************************************
                 * 来店予約：店舗検索JSONの取得完了.
                 ***************************************************************************/
                else if (end_point === ApiCaller.EndPoints.ReserveStoreSearch) {
                    if(this._reserve != null) {
                        this._reserve.updateStoreList(data.shops);
                    }
                    if(this._contactUs != null) {
                      this._contactUs.updateStoreList(data.shops);
                    }
                }
                /**************************************************************************
                 * 商品検索条件JSONの取得完了.
                 ***************************************************************************/
                else if (end_point === ApiCaller.EndPoints.ProductSearchConditionJson) {
                    this._menuManager._productMenu.initializeSearchCondition(data);
                    this._menuManager._productMenu.restoreSearchConditionFromQuery();
                }
                /**************************************************************************
                 * マエストロカード設問JSONの取得完了.
                 ***************************************************************************/
                else if (end_point === ApiCaller.EndPoints.MaestroCardQuestionJson) {
                    if(this._cardManager.getIsInitialized() === false) {
                        this._cardManager.initializeQuestions(data);
                        this._cardManager.initializeMenuQuestions(data);
                        // メニューの復元、初期ヴァリデート.
                        this._cardManager.restoreMenuSelectedValues();
                        this._cardManager.menuValidate(null);
                        this._cardManager.setIsInitialized(true);
                    }

                }
                /**************************************************************************
                 * マエストロカード作成完了.
                 ***************************************************************************/
                else if (end_point.indexOf(ApiCaller.EndPoints.PostMaestroCard) !== -1 && method === "post") {
                    this._storage.saveUserId(data);
                }

                /**************************************************************************
                 * WEB試着削除完了.
                 ***************************************************************************/
                else if (end_point.indexOf(ApiCaller.EndPoints.WebTryOn) !== -1 && method === "delete") {
                    this._apiCaller.getUserInfo();
                }
                /**************************************************************************
                 * WEB試着登録完了.
                 ***************************************************************************/
                else if (end_point.indexOf(ApiCaller.EndPoints.WebTryOn) !== -1 && method === "post") {
                    this._storage.saveUserId(data);
                    this._apiCaller.getUserInfo();
                }
                /**************************************************************************
                 * レコメンド取得完了.
                 ***************************************************************************/
                else if (end_point === ApiCaller.EndPoints.RecommendedItems) {
                    if(this._recommend != null) {
                        this._recommend.setRecommendData(data.items);
                        this._recommend.clearList();
                        this._recommend.updateRecommendList(0);
                    }
                }


                break;
        }
    }

    /***************************************************************************
     * WEB試着情報削除.
     ***************************************************************************/
    deleteWebTryOnList(itemsColors_id) {

        // 画面内に同じitemColors_idのaタグがあったら-checkedクラスを取る.
        const webTryOnButton = $(".web-try-on-button[data-item-id="+itemsColors_id+"]");
        if(webTryOnButton.length === 1) {
            webTryOnButton.removeClass("-checked");
        }
        this._apiCaller.deleteWebTryOn(itemsColors_id);
    }


    /***************************************************************************
     * スマートフォン判定.
     ***************************************************************************/
    isSmartPhone() {
        const ua = navigator.userAgent;
        if (ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0) {
            return true;
        } else if (ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0) {
            return true;
        } else {
            return false;
        }
    }


    /***************************************************************************
     * 数値の桁区切り.
     ***************************************************************************/
    number_format(num) {
        return num.toString().replace(/([0-9]+?)(?=(?:[0-9]{3})+$)/g, '$1,');
    }

    showAlertModal(subject, message) {
        $(".alert-modal .c-buy__head").html(subject);
        $(".alert-modal .u-textCenter").html(message);
        $(".alert-modal").addClass("-show");
    }


    /****************************************************************************
     * 既にWEB試着リストにあるIDかどうか調べる.
     * @param itemId
     ***************************************************************************/
    isAlreadyInList(itemId) {
        if(this._userInfo.tryOnList != null) {
            const items = this._userInfo.tryOnList.items;
            for(let ii=0;ii<items.length;ii++) {
                if(items[ii]["itemsColors_id"] == itemId) {
                    return true;
                }
            }
        }

        return false;
    }


    /***************************************************************************
     * WEB試着リストへの登録トグル.
     * @param itemId
     * @returns {boolean}
     **************************************************************************/
    toggleWebTryOn(itemId) {

        const hasInList = this.isAlreadyInList(itemId);

        // 既に3件登録がある場合はアラート表示してreturn.
        if(!hasInList && this._userInfo.tryOnList.items.length === 3) {
          this.showAlertModal("お気に入りに追加","お気に入りに追加できる商品は<br>最大3点です。");
            return false;
        }

        if(hasInList) {
            this._apiCaller.deleteWebTryOn(itemId);
            return false;
        }else{
            this._apiCaller.postWebTryOn(itemId);
            return true;
        }
    }


    /****************************************************************************
     * 削除されたものがある場合はモーダル表示.
     * @param data
     ***************************************************************************/
    judgeIsDeletedTryOn(data) {
        if(data.tryOnList == null) {
            return false;
        }

        // 試着リストに在庫切れ商品があった場合、マエストロメニューを開いたタイミングでアラート表示する.
        // 為にローカルストレージにフラグを保存.
        if(data.tryOnList.is_deleted) {
            this._storage.saveOutOfStockNotice();
        }else{
            this._storage.clearOutOfStockNotice();
        }
    }


    /****************************************************************************
     * baseElement内の.swiper-containerに対してSwiperを適応する.
     * @param baseElement
     ***************************************************************************/
    applySwiper(baseElement) {
        var $slider = baseElement.filter(function() {
            return $(this).children('.c-productMain__thumb.swiper-wrapper').length !== 0;
        });

        if ($slider.length === 0) {
            return;
        }

        $slider.each(function() {
            var isNested = $(this).parent().closest('.swiper-container').length !== 0;
            var instance;
            var $thumbnailList;
            var $thumbnailItem;

            instance = new Swiper(this, $.extend({
                nested: isNested,
                loop: true,
                simulateTouch: true
            }, this.querySelectorAll('.swiper-slide').length < 2 ? {} : {
                pagination: {
                    el: this.querySelector('.swiper-pagination'),
                    clickable: true
                }
            }));

            $thumbnailList = $(this).closest('.c-productMain').find('.c-productLabel__list');
            if ($thumbnailList.length === 1) {
                $thumbnailItem = $thumbnailList.find('li');
                $thumbnailItem.on('click', function() {
                    instance.slideTo($thumbnailItem.index(this) + 1);
                });
                $thumbnailItem.eq(this.swiper.activeIndex - 1).addClass('-active');
                this.swiper.on('slideChange', function() {
                    // console.log("previousIndex", this.previousIndex, "activeIndex", this.activeIndex);
                    // SPで3つ目を越えた際にドットのアクティブがつかないことへのパッチ.
                    let tmpActiveIndex = this.activeIndex;
                    if(tmpActiveIndex > $thumbnailItem.length) {
                        tmpActiveIndex = 1;
                    }
                    $thumbnailItem.eq(this.previousIndex - 1).removeClass('-active');
                    $thumbnailItem.eq(tmpActiveIndex - 1).addClass('-active');
                });
            }
        });
    }


    /****************************************************************************
     * TOPページのムービーモーダル制御.
     ****************************************************************************/
    setupTopMovie() {
        $(".p-topMovie__thumb a").on("click", function() {
            $('.-topMovie').addClass('-show');
            $('.-topMovie .p-topMovie__modal').html('<iframe src="https://www.youtube.com/embed/7Myv3NyELtc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>');
            return false;
        });

        $(".js-modal.-topMovie .js-modal__closeBtn").on("click", function() {
            $('.-topMovie').removeClass('-show');
            $('.-topMovie .p-topMovie__modal').html("");
            return false;
        });
    }
}

$(function () {
    const controller = new Controller();
    controller.initialize();
});

