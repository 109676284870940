import {ApiCaller} from "./api-caller";
import {MenuManager} from "./menu-manager";

export class CardManager {
    constructor(storage, controller) {
        this._controller = controller;
        this._storage = storage;
        this._wrapper = $(".js-modal.-maestro");
        this._menuWrapper = $(".l-headerNav__li.maestro .c-menuform.maestro");
        this._pcPages = [".-page0"];
        this._spPages = [".-page0", ".-page1"];
        this._questionPages = [".-page0,.-page1", ".-page2", ".-page3", ".-page4", ".-page5", ".-page6"];

        this._judgedModalOpen = false;
        this._currentQuestion = 0;
        this._isInitialized = false;
    }

    initialize() {
        this.initializeElements();
        this.setupEvents();
    }

    setIsInitialized(isInitialized) {
        this._isInitialized = isInitialized;
    }

    getIsInitialized() {
        return this._isInitialized;
    }

    judgeModalOpen() {

        // マエストロカード作成Popupは批評にに変更.
        // 2021年9月28日.
        return false;

        // はじめの1回だけ処理する
        if(!this._judgedModalOpen) {
            this._judgedModalOpen = true;
            // 特定のURLクエリがある場合は処理しない.
            if (location.href.match(/no-card=true/) != null) {
                return false;
            }

            // マエストロカードを作成済みか、今は作成しないボタンを押していたら表示しない.
            const isMaestroSkipped = this._storage.getMaestroSkipped();
            const userInfo = this._controller._userInfo;

            if (!isMaestroSkipped && userInfo.maestroCard == null) {
                $(".-maestro").addClass("-show");
            }
        }
    }

    initializeElements() {
        this._wrapper.find(".c-menuform__dd.gender").empty();
        this._wrapper.find(".c-menuform__dd.face").empty();
        this._wrapper.find(".c-menuform__dd.style").empty();
        this._wrapper.find(".c-menuform__dd.work").empty();
        this._wrapper.find(".c-menuform__dd.hobby").empty();

        this._menuWrapper.find(".c-menuform__dd.gender").empty();
        this._menuWrapper.find(".c-menuform__dd.face").empty();
        this._menuWrapper.find(".c-menuform__dd.style").empty();
        this._menuWrapper.find(".c-menuform__dd.work").empty();
        this._menuWrapper.find(".c-menuform__dd.hobby").empty();
    }


    /***************************************************************************************************************
     * メニュー用の質問を生成.
     * @param data
     ***************************************************************************************************************/
    initializeMenuQuestions(data) {
        const _this = this;

        // 性別.
        const genderList = data["maestroCard_question"][CardManager.QuestionIndex.Gender]["question_option"];
        for (let ii = 0; ii < genderList.length; ii++) {

            // 選択肢を生成.
            let span = $('<span class="c-menuform__radio">');
            let input = $('<input type="radio" name="maestro-gender-menu" id="meastro-gender_' + genderList[ii].option_id + '-menu" value="' + genderList[ii].option_id + '">');
            let label = $('<label class="c-menuform__radioLabel" for="meastro-gender_' + genderList[ii].option_id + '-menu">' + genderList[ii].option_text + '</label>');
            span.append(input);
            span.append(label);
            this._menuWrapper.find(".c-menuform__dd.gender").append(span);
        }

        // 顔型.
        const faceTypeList = data["maestroCard_question"][CardManager.QuestionIndex.FaceType]["question_option"];
        for (let ii = 0; ii < faceTypeList.length; ii++) {

            // 選択肢を生成.
            let span = $('<span class="c-menuform__radio">');
            let input = $('<input type="radio" name="maestro-faceType-menu" id="meastro-faceType_' + faceTypeList[ii].option_id + '-menu" value="' + faceTypeList[ii].option_id + '">');
            let label = $('<label class="c-menuform__radioLabel" for="meastro-faceType_' + faceTypeList[ii].option_id + '-menu">' + faceTypeList[ii].option_text + '</label>');
            span.append(input);
            span.append(label);
            this._menuWrapper.find(".c-menuform__dd.face").append(span);
        }

        // なりたい自分.
        const wishList = data["maestroCard_question"][CardManager.QuestionIndex.Wish]["question_option"];
        for (let ii = 0; ii < wishList.length; ii++) {

            // 選択肢を生成.
            let span = $('<span class="c-checkboxRound">');
            let input = $('<input type="checkbox" name="maestro-wish-menu" id="meastro-wish_' + wishList[ii].option_id + '-menu" value="' + wishList[ii].option_id + '">');
            let label = $('<label for="meastro-wish_' + wishList[ii].option_id + '-menu">' + wishList[ii].option_text + '</label>');
            if (typeof wishList[ii].not_in_gender !== "undefined") {
                input.data("not_in_gender", wishList[ii].not_in_gender.split(","));
            }
            span.append(input);
            span.append(label);
            this._menuWrapper.find(".c-menuform__dd.style").append(span);
        }


        // ワークスタイル.
        const workStyleList = data["maestroCard_question"][CardManager.QuestionIndex.WorkStyle]["question_option"];
        for (let ii = 0; ii < workStyleList.length; ii++) {

            // 選択肢を生成.
            let span = $('<span class="c-checkboxRound">');
            let input = $('<input type="checkbox" name="maestro-workStyle-menu" id="meastro-workStyle_' + workStyleList[ii].option_id + '-menu" value="' + workStyleList[ii].option_id + '">');
            let label = $('<label for="meastro-workStyle_' + workStyleList[ii].option_id + '-menu">' + workStyleList[ii].option_text + '</label>');
            span.append(input);
            span.append(label);
            this._menuWrapper.find(".c-menuform__dd.work").append(span);
        }


        // 趣味.
        const hobbyList = data["maestroCard_question"][CardManager.QuestionIndex.Hobby]["question_option"];
        for (let ii = 0; ii < hobbyList.length; ii++) {

            // 選択肢を生成.
            let span = $('<span class="c-checkboxRound">');
            let input = $('<input type="checkbox" name="maestro-hobby-menu" id="meastro-hobby_' + hobbyList[ii].option_id + '-menu" value="' + hobbyList[ii].option_id + '">');
            let label = $('<label for="meastro-hobby_' + hobbyList[ii].option_id + '-menu">' + hobbyList[ii].option_text + '</label>');
            span.append(input);
            span.append(label);
            this._menuWrapper.find(".c-menuform__dd.hobby").append(span);
        }


        // メニューは1画面なので一括でヴァリデート.
        this._menuWrapper.find("input[name^=maestro-]").on("click", function () {
            _this.menuValidate($(this));
        });
    }


    /**************************************************************************************************************
     * マエストロカードモーダルの質問を生成.
     * @param data
     ***************************************************************************************************************/
    initializeQuestions(data) {

        const _this = this;

        // 性別.
        const genderList = data["maestroCard_question"][CardManager.QuestionIndex.Gender]["question_option"];
        for (let ii = 0; ii < genderList.length; ii++) {

            // 選択肢を生成.
            let span = $('<span class="c-menuform__radio">');
            let input = $('<input type="radio" name="maestro-gender" id="meastro-gender_' + genderList[ii].option_id + '" value="' + genderList[ii].option_id + '">');
            let label = $('<label class="c-menuform__radioLabel" for="meastro-gender_' + genderList[ii].option_id + '">' + genderList[ii].option_text + '</label>');
            span.append(input);
            span.append(label);
            this._wrapper.find(".c-menuform__dd.gender").append(span);
        }

        // 必須なのでonchangeでvalidate.
        this._wrapper.find("input[name=maestro-gender]").on("click", function () {
            _this.validate(_this._currentQuestion);
        });

        // 顔型.
        const faceTypeList = data["maestroCard_question"][CardManager.QuestionIndex.FaceType]["question_option"];
        for (let ii = 0; ii < faceTypeList.length; ii++) {

            // 選択肢を生成.
            let span = $('<span class="c-menuform__radio">');
            let input = $('<input type="radio" name="maestro-faceType" id="meastro-faceType_' + faceTypeList[ii].option_id + '" value="' + faceTypeList[ii].option_id + '">');
            let label = $('<label class="c-menuform__radioLabel" for="meastro-faceType_' + faceTypeList[ii].option_id + '">' + faceTypeList[ii].option_text + '</label>');
            span.append(input);
            span.append(label);
            this._wrapper.find(".c-menuform__dd.face").append(span);
        }

        // 必須なのでonchangeでvalidate.
        this._wrapper.find("input[name=maestro-faceType]").on("click", function () {
            _this.validate(_this._currentQuestion);
        });


        // なりたい自分.
        const wishList = data["maestroCard_question"][CardManager.QuestionIndex.Wish]["question_option"];
        for (let ii = 0; ii < wishList.length; ii++) {

            // 選択肢を生成.
            let span = $('<span class="c-menuform__radio">');
            let input = $('<input type="checkbox" name="maestro-wish" id="meastro-wish_' + wishList[ii].option_id + '" value="' + wishList[ii].option_id + '">');
            let label = $('<label class="c-menuform__radioLabel" for="meastro-wish_' + wishList[ii].option_id + '">' + wishList[ii].option_text + '</label>');
            if (typeof wishList[ii].not_in_gender !== "undefined") {
                input.data("not_in_gender", wishList[ii].not_in_gender.split(","));
            }
            span.append(input);
            span.append(label);
            this._wrapper.find(".c-menuform__dd.style").append(span);
        }

        // 最大3件までなのでonchangeでvalidate.
        this._wrapper.find("input[name=maestro-wish]").on("click", function () {
            _this.validate(_this._currentQuestion, $(this));
        });

        // ワークスタイル.
        const workStyleList = data["maestroCard_question"][CardManager.QuestionIndex.WorkStyle]["question_option"];
        for (let ii = 0; ii < workStyleList.length; ii++) {

            // 選択肢を生成.
            let span = $('<span class="c-menuform__radio">');
            let input = $('<input type="checkbox" name="maestro-workStyle" id="meastro-workStyle_' + workStyleList[ii].option_id + '" value="' + workStyleList[ii].option_id + '">');
            let label = $('<label class="c-menuform__radioLabel" for="meastro-workStyle_' + workStyleList[ii].option_id + '">' + workStyleList[ii].option_text + '</label>');
            span.append(input);
            span.append(label);
            this._wrapper.find(".c-menuform__dd.work").append(span);
        }


        // 趣味.
        const hobbyList = data["maestroCard_question"][CardManager.QuestionIndex.Hobby]["question_option"];
        for (let ii = 0; ii < hobbyList.length; ii++) {

            // 選択肢を生成.
            let span = $('<span class="c-menuform__radio">');
            let input = $('<input type="checkbox" name="maestro-hobby" id="meastro-hobby_' + hobbyList[ii].option_id + '" value="' + hobbyList[ii].option_id + '">');
            let label = $('<label class="c-menuform__radioLabel" for="meastro-hobby_' + hobbyList[ii].option_id + '">' + hobbyList[ii].option_text + '</label>');
            span.append(input);
            span.append(label);
            this._wrapper.find(".c-menuform__dd.hobby").append(span);
        }


        // 全ての選択状態を保存するため、共通のコールバックを呼ぶ.
        this._wrapper.find("input[name^=maestro-]").on("click", function () {
            _this.saveSelectedValues();
        });
    }


    setupEvents() {
        const _this = this;

        /***********************************************
         * 始めるボタン.
         ***********************************************/
        this._wrapper.find(".create-card-button").on("click", function () {
            if (_this._controller.isSmartPhone()) {
                if (_this._wrapper.find(".-page1:visible").length !== 0) {
                    _this.nextQuestion();
                } else {
                    _this.secondTitle();
                }

            } else {
                _this.nextQuestion();
            }
        });


        /***********************************************
         * 次へボタン.
         ***********************************************/
        this._wrapper.find(".next-button").on("click", function (e) {
            e.preventDefault();
            if (_this.validate(_this._currentQuestion)) {
                _this.nextQuestion();
            }
            return false;
        });


        /***********************************************
         * スキップボタン.
         ***********************************************/
        this._wrapper.find(".skip-button").on("click", function (e) {

            _this.nextQuestion();
            return false;
        });


        /***********************************************
         * 今はカードを作らないボタン.
         ***********************************************/
        this._wrapper.find(".non-create-card-button").on("click", function () {
            _this._storage.saveMaestroSkipped();
            $(".-maestro").removeClass("-show");
        });


        /***********************************************
         * 閉じるボタン.
         ***********************************************/
        this._wrapper.find(".maestro-close-button").on("click", function () {
            $(".-maestro").removeClass("-show");
            _this._storage.saveMaestroSkipped();
            // カード作成済みの場合はTOPへリダイレクト.
            if (_this._currentQuestion === 5) {
                location.href = "/";
            }
        });


        /***********************************************
         * TOPページボタン.
         ***********************************************/
        this._wrapper.find(".toppage-button").on("click", function () {
            $(".-maestro").removeClass("-show");
            // TOPへリダイレクト.
            location.href = "/";
        })


        /**************************************************
         * (メニュー）更新ボタン.
         *************************************************/
        this._menuWrapper.find(".update-button").on("click", function (e) {
            e.preventDefault();
            _this.submitMenuCardInfo();
            _this._controller._apiCaller.getRecommendedItems();
            _this._controller._menuManager._cardMenu.showModal("トップページの<br>マエストロからのご提案内容が<br class='SPbr'>更新されました。", true);
            $(".maestro-check-button").off().on("click", function() {
              _this._controller._menuManager.toggleMenuAt(MenuManager.ClassName.Maestro);
              _this._controller._menuManager._cardMenu.hideModal();
              if($("#maestro").length === 0) {
                location.href = '/#maestro';
              }else{
                location.hash = 'maestro';
                location.reload();
              }

            })
            // location.href = '/#maestro';
            // _this._controller._menuManager.toggleMenuAt(MenuManager.ClassName.Maestro);
            return false;
        });


        this._menuWrapper.find(".reset-button").on("click", function(e) {
           e.preventDefault();
           _this.resetMenuSelectedValue();
           _this.menuValidate();
           const scrollTo = $(".l-headerNav__li.maestro .p-form").position().top;
            $(".l-headerNav__li.maestro .l-menu").animate({scrollTop: $(".l-headerNav__li.maestro .l-menu").scrollTop() + scrollTo}, 400);
           return false;
        });


    }


    /*************************************************************************************************************
     * 2つ目のタイトルページ（SPのみ）
     *************************************************************************************************************/
    secondTitle() {
        this._wrapper.find(".-page0").hide();
        this._wrapper.find(".-page1").show();
    }

    /*************************************************************************************************************
     * 次の設問.
     *************************************************************************************************************/
    nextQuestion() {

        // 端末によってページ展開を切り替え.
        const pages = this._questionPages;

        // 表示中を非表示.
        this._wrapper.find(pages[this._currentQuestion]).hide();

        // 次ページを表示
        this._currentQuestion++;
        this._wrapper.find(pages[this._currentQuestion]).show();

        // 最終問題の場合はサーバへ送信.
        if (this._currentQuestion === 5) {
            this.submitCardInfo();
        }

        // 回答内容をlocalStorageから復元.
        this.restoreSelectedValues();

        // ヴァリデート.
        this.validate(this._currentQuestion);

        // Progress更新.
        this.updateProgess();
    }


    /****************************************************************************************************************
     * メニューのヴァリデート.
     ***************************************************************************************************************/
    menuValidate(elm) {

        let validateOkFlag = true;

        // 性別の選択によって「なりたい自分」を制御.
        let genderValue = this._menuWrapper.find("input[name=maestro-gender-menu]:checked").val();
        if (typeof genderValue !== "undefined") {
            this._menuWrapper.find("input[name=maestro-wish-menu]").each(function () {
                const notInGender = $(this).data("not_in_gender");
                if (notInGender && $.inArray(genderValue, notInGender) != -1) {
                    $(this).prop("checked", false);
                    $(this).parent().css({opacity: .3, pointerEvents: "none"});
                } else {
                    $(this).parent().css({opacity: 1, pointerEvents: "auto"});
                }
            });
        }

        // なりたい自分は3つまで.
        if (elm != null && elm.attr("name") === "maestro-wish-menu") {
            const workStyleCount = this._menuWrapper.find("input[name=maestro-wish-menu]:checked").length;
            if (workStyleCount > 3) {
                elm.prop("checked", false);
                return false;
            }
        }

        // 顔、性別の選択は必須.
        if (this._menuWrapper.find("input[name=maestro-gender-menu]:checked").length === 0 || this._menuWrapper.find("input[name=maestro-faceType-menu]:checked").length === 0) {
            validateOkFlag = false;
        }


        /*****************************************************
         * ヴァリデート結果で更新ボタンの生き死にを決定.
         *****************************************************/
        if (validateOkFlag) {
            this._menuWrapper.find("button.update-button").css({opacity: 1, pointerEvents: "auto"});
        } else {
            this._menuWrapper.find("button.update-button").css({opacity: .3, pointerEvents: "none"});
        }
    }


    /****************************************************************************************************************
     * ヴァリデート.
     ***************************************************************************************************************/
    validate(questionIndex, inputElement) {

        const _this = this;
        let currentPage = this.getCurrentPageElement();
        let validateOkFlag = true;

        switch (questionIndex) {

            /*******************************************************************************
             * 設問1(必須)
             * 性別・顔型
             ******************************************************************************/
            case 1:

                // 性別、顔型を選択していないと次へ進めなくする.
                if (this._wrapper.find("input[name=maestro-gender]:checked").length === 0 || this._wrapper.find("input[name=maestro-faceType]:checked").length === 0) {
                    currentPage.find(".next-button").css("opacity", .5);
                    validateOkFlag = false;
                } else {
                    currentPage.find(".next-button").css("opacity", 1);
                }

                break;


            /*******************************************************************************
             * 設問2
             * なりたい自分
             ******************************************************************************/
            case 2:

                // 性別によって選択できないものがあるので調査する.
                currentPage.find("input[name=maestro-wish]").each(function () {

                    $(this).parent().show();

                    if ($(this).data("not_in_gender")) {
                        const not_in_gender_array = $(this).data("not_in_gender");

                        // モーダルでは設問2に来た時点で性別は必ず選択されている.
                        if ($.inArray(_this._wrapper.find("input[name=maestro-gender]:checked").val(), not_in_gender_array) != -1) {
                            $(this).prop("checked", false);
                            $(this).parent().hide();
                            _this.saveSelectedValues();
                        } else {
                            $(this).parent().show();
                        }
                    }
                });

                // 最大3件まで.
                // if (this._wrapper.find("input[name=maestro-wish]:checked").length > 3) {
                //     currentPage.find(".next-button").css("opacity", .5);
                //     validateOkFlag = false;
                // } else {
                //     currentPage.find(".next-button").css("opacity", 1);
                // }
                if (this._wrapper.find("input[name=maestro-wish]:checked").length > 3) {
                    inputElement.prop("checked", false);
                }
                break;


            /*******************************************************************************
             * 設問3
             * ワークスタイル
             ******************************************************************************/
            case 3:



                break;


            /*******************************************************************************
             * 設問4
             * 趣味
             ******************************************************************************/
            case 4:
                break;

        }


        return validateOkFlag;
    }

    /****************************************************************************************************************
     * 選択値の保存.
     ***************************************************************************************************************/
    saveSelectedValues() {
        let selectedValues = [];
        const currentPage = this.getCurrentPageElement();

        // 1問目は1画面に2つはいっているので例外処理.
        if (this._currentQuestion === 1) {

            // 選択された要素を抽出.
            selectedValues = [];
            const selectedGender = currentPage.find("input[name=maestro-gender]:checked");
            selectedGender.each(function () {
                selectedValues.push($(this).val());
            });
            this._storage.saveMaestroCardQuestion("gender", selectedValues);

            // 選択された要素を抽出.
            selectedValues = [];
            const selectedFaceType = currentPage.find("input[name=maestro-faceType]:checked");
            selectedFaceType.each(function () {
                selectedValues.push($(this).val());
            });
            this._storage.saveMaestroCardQuestion("faceType", selectedValues);

        } else {
            // 選択された要素を抽出.
            const selectedElements = currentPage.find("input[name^=maestro-]:checked");
            selectedElements.each(function () {
                selectedValues.push($(this).val());
            });

            this._storage.saveMaestroCardQuestion(this._currentQuestion, selectedValues);
        }
    }


    /****************************************************************************************************************
     * 選択値の復元.
     ***************************************************************************************************************/
    restoreSelectedValues() {

        // 顔型と性別は例外処理.
        if (this._currentQuestion === 1) {

            // 性別.
            let restoreData = this._storage.loadMaestroCardQuestion("gender");
            if (restoreData.length > 0) {
                const currentPage = this.getCurrentPageElement();
                currentPage.find("input[name=maestro-gender]").each(function () {
                    if ($.inArray($(this).val(), restoreData) !== -1) {
                        $(this).prop("checked", true);
                    }
                });
            }

            // 顔型.
            restoreData = this._storage.loadMaestroCardQuestion("faceType");
            if (restoreData.length > 0) {
                const currentPage = this.getCurrentPageElement();
                currentPage.find("input[name=maestro-faceType]").each(function () {
                    if ($.inArray($(this).val(), restoreData) !== -1) {
                        $(this).prop("checked", true);
                    }
                });
            }


        } else {
            let restoreData = this._storage.loadMaestroCardQuestion(this._currentQuestion);
            if (restoreData.length > 0) {
                const currentPage = this.getCurrentPageElement();
                currentPage.find("input[name^=maestro-]").each(function () {
                    if ($.inArray($(this).val(), restoreData) !== -1) {
                        $(this).prop("checked", true);
                    }
                });
            }
        }
    }


    /****************************************************************************************************************
     * メニュー選択値の復元.
     ***************************************************************************************************************/
    restoreMenuSelectedValues() {


        // 性別.
        let restoreData = this._storage.loadMaestroCardQuestion("gender");
        if (restoreData.length > 0) {
            this._menuWrapper.find("input[name=maestro-gender-menu]").each(function () {
                if ($.inArray($(this).val(), restoreData) !== -1) {
                    $(this).prop("checked", true);
                }
            });
        }

        // 顔型.
        restoreData = this._storage.loadMaestroCardQuestion("faceType");
        if (restoreData.length > 0) {
            this._menuWrapper.find("input[name=maestro-faceType-menu]").each(function () {
                if ($.inArray($(this).val(), restoreData) !== -1) {
                    $(this).prop("checked", true);
                }
            });
        }

        const otherFields = ["maestro-wish-menu", "maestro-workStyle-menu", "maestro-hobby-menu"];
        for (let ii = 0; ii<otherFields.length; ii++) {
            let restoreData = this._storage.loadMaestroCardQuestion(ii+2);
            if (restoreData.length > 0) {
                this._menuWrapper.find("input[name="+otherFields[ii]+"]").each(function () {
                    if ($.inArray($(this).val(), restoreData) !== -1) {
                        $(this).prop("checked", true);
                    }
                });
            }
        }

    }


    updateProgess() {
        const progress = this._wrapper.find(".p-maestroProgress > div");
        for (let ii = 0; ii < this._currentQuestion; ii++) {
            progress.eq((ii + 1)).addClass("-current");
        }
    }

    getCurrentPageElement() {
        if (this._currentQuestion > 0) {
            return this._wrapper.find(this._questionPages[this._currentQuestion]);
        }
        return null;
    }

    submitCardInfo() {
        let param = {};
        param.maestroCard_q1 = this._wrapper.find("input[name=maestro-gender]:checked").val();
        param.maestroCard_q2 = this._wrapper.find("input[name=maestro-faceType]:checked").val();
        param.maestroCard_q3 = [];
        this._wrapper.find("input[name=maestro-wish]:checked").each(function () {
            param.maestroCard_q3.push($(this).val());
        });
        param.maestroCard_q4 = [];
        this._wrapper.find("input[name=maestro-workStyle]:checked").each(function () {
            param.maestroCard_q4.push($(this).val());
        });
        param.maestroCard_q5 = [];
        this._wrapper.find("input[name=maestro-hobby]:checked").each(function () {
            param.maestroCard_q5.push($(this).val());
        });

        this._controller._apiCaller.postMaestroCard(param);
    }

    resetMenuSelectedValue() {
        this._menuWrapper.find("input[name=maestro-gender-menu][value=1]").prop("checked", true);
        this._menuWrapper.find("input[name=maestro-faceType-menu][value=1]").prop("checked", true);
        this._menuWrapper.find("input[name=maestro-wish-menu]").prop("checked", false);
        this._menuWrapper.find("input[name=maestro-workStyle-menu]").prop("checked", false);
        this._menuWrapper.find("input[name=maestro-hobby-menu]").prop("checked", false);
    }

    submitMenuCardInfo() {

        /***************************************
         * メニューの場合はこのタイミングでlocalStorageにも保存する.
         * @type {Array}
         ***************************************/
        // 選択された要素を抽出.
        let selectedValues = [];
        const selectedGender = this._menuWrapper.find("input[name=maestro-gender-menu]:checked");
        selectedGender.each(function () {
            selectedValues.push($(this).val());
        });
        this._storage.saveMaestroCardQuestion("gender", selectedValues);

        // 選択された要素を抽出.
        selectedValues = [];
        const selectedFaceType = this._menuWrapper.find("input[name=maestro-faceType-menu]:checked");
        selectedFaceType.each(function () {
            selectedValues.push($(this).val());
        });
        this._storage.saveMaestroCardQuestion("faceType", selectedValues);

        // 選択された要素を抽出.
        const otherFields = ["maestro-wish-menu", "maestro-workStyle-menu", "maestro-hobby-menu"];
        for(let ii=0;ii<otherFields.length;ii++) {
            selectedValues = [];
            const selectedElements = this._menuWrapper.find("input[name=" + otherFields[ii] + "]:checked");
            selectedElements.each(function () {
                selectedValues.push($(this).val());
            });
            this._storage.saveMaestroCardQuestion((ii+2), selectedValues);
        }


        /****************************************
         * APIへPOST.
         ****************************************/
        let param = {};
        param.maestroCard_q1 = this._menuWrapper.find("input[name=maestro-gender-menu]:checked").val();
        param.maestroCard_q2 = this._menuWrapper.find("input[name=maestro-faceType-menu]:checked").val();
        param.maestroCard_q3 = [];
        this._menuWrapper.find("input[name=maestro-wish-menu]:checked").each(function () {
            param.maestroCard_q3.push($(this).val());
        });
        param.maestroCard_q4 = [];
        this._menuWrapper.find("input[name=maestro-workStyle-menu]:checked").each(function () {
            param.maestroCard_q4.push($(this).val());
        });
        param.maestroCard_q5 = [];
        this._menuWrapper.find("input[name=maestro-hobby-menu]:checked").each(function () {
            param.maestroCard_q5.push($(this).val());
        });

        this._controller._apiCaller.postMaestroCard(param);
    }
}

CardManager.QuestionIndex = {};
CardManager.QuestionIndex.Gender = 0;
CardManager.QuestionIndex.FaceType = 1;
CardManager.QuestionIndex.Wish = 2;
CardManager.QuestionIndex.WorkStyle = 3;
CardManager.QuestionIndex.Hobby = 4;

