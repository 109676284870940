import {ApiCaller} from "./api-caller";
import {MenuManager} from "./menu-manager";

export class ItemList {

    constructor(controller) {
        this._controller = controller;
    }

    initialize() {
        this.initializeElements();
        this.setupEvents();
    }

    initializeElements() {

        const _this = this;

        if($(".p-list__product").length !== 0) {
            $(".p-list__product.c-product").each(function() {
                _this._controller.applySwiper($(this).find(".swiper-container"));
            });
        }else{
            _this._controller.applySwiper($(".c-productMain__contents .swiper-container"));
        }
    }

    setupEvents() {

        const _this = this;

        // 一覧.
        // $(".product-list article").each(function() {
        //     $(this).find(".c-productMain__try .web-try-on-button").on("click", function() {
        //         const isAdded = _this._controller.toggleWebTryOn($(this).data("itemId"));
        //         if(isAdded) {
        //             $(this).addClass("-checked");
        //         }else{
        //             $(this).removeClass("-checked");
        //         }
        //     });
        // });
        //
        // // 詳細
        $(".c-productMain__try .web-try-on-button").on("click", function() {
            const isAdded = _this._controller.toggleWebTryOn($(this).data("itemId"));
            if(isAdded) {
                $(this).addClass("-checked");
            }else{
                $(this).removeClass("-checked");
            }
        });

    }


    overrideWebTryOnNavigate() {

        const _this = this;

        $(".c-productItem a[href^=\\/web-try-on]").on("click", function() {
            // PCはモーダル開く.
            if(_this._controller.isSmartPhone() === false) {
                $(".generator-alert-modal").addClass("-show");
                return false;
            }

            return true;
        })

    }


}


